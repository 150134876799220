/*
 * @Author: princemwang
 * @Date: 2022-06-30 22:15:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-19 15:11:59
 */
import MutedPng from '@/assets/imgs/muted.png';
import aegisUtils from '@/utils/aegisReport';
import { useEffect } from 'react';

export const MutedTooltip = ({ visible = false, visibleChange }: { visible: boolean; visibleChange: () => void }) => {
  const value = visible ? 1 : 0;
  const { aegis } = aegisUtils();
  const style = {
    transform: `scale(${value})`,
  };
  useEffect(() => {
    if (visible) {
      aegis.reportEvent({
        name: '静音提示', // 必填
        ext1: '当前静音tooltip已显示',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <div
      className="tootip-fix"
      style={style}
      onClick={() => {
        aegis.reportEvent({
          name: '静音提示', // 必填
          ext1: '当前静音tooltip已关闭',
        });
        visibleChange();
      }}
    >
      <img src={MutedPng} />
      <span className="tootip-text">Click to turn on sound</span>
    </div>
  );
};
