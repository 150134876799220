/*
 * @Author: princemwang
 * @Date: 2022-06-17 17:50:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-29 11:22:42
 */
import Home from '@/pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LiveHome } from '@/pages/liveAndPlayback';
import { RegisterHome } from '@/pages/guestRegister';
import config from './config';
import aegisUtils from '@/utils/aegisReport';
import { regPath } from '@/utils';
import { verifyWebcast } from '@/api';
import { useEffect, useState } from 'react';
import { Authentication } from '@/pages/Authentication';
import { Priview } from '@/components/liveAndPlayback/pptPagination/Preview';
import NotFound from '@/pages/NotFound';
import { createInitFormText } from '@/globalState';
import VirtualPage from '@/pages/virtual';
import Cookie from 'js-cookie';
import VConsole from 'vconsole';
import './App.css';

function App() {
  const [state, setState] = useState<'loading' | 'empty' | 'found'>('loading');
  const [, setInitForm] = createInitFormText();

  useEffect(() => {
    try {
      const initAegis = aegisUtils();
      initAegis.init();
      if (window.location.search === '?debug') {
        const vConsole = new VConsole({ theme: 'dark' });
        vConsole.setSwitchPosition(0, 200);
      }
      const verifyWebcastHandle = async () => {
        try {
          const { webcastId } = config;
          const res = await verifyWebcast({ webcastId });
          if (res.errorCode === 0) {
            const { title } = res.data;
            document.title = title;
            setInitForm(res.data);
            setState('found');
          } else if (res.errorCode === 101) {
            // webcastId失效,清除cookie
            Cookie.remove(webcastId);
            setState('empty');
          } else {
            setState('empty');
          }
        } catch (error) {
          console.log(error);
          setState('empty');
        }
      };
      verifyWebcastHandle();
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isRuleOrderly = regPath(config.webcastId);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Authentication state={state}>{isRuleOrderly ? <Home></Home> : <NotFound></NotFound>}</Authentication>
            }
          >
            <Route
              path={`live/${config.webcastId}`}
              element={
                <Authentication state={state}>
                  <LiveHome></LiveHome>
                </Authentication>
              }
            ></Route>
            <Route
              path={config.webcastId}
              element={
                <Authentication state={state}>
                  <RegisterHome></RegisterHome>
                </Authentication>
              }
            ></Route>
            <Route
              path={`virtual/${config.webcastId}`}
              element={
                <Authentication state={state}>
                  <VirtualPage></VirtualPage>
                </Authentication>
              }
            ></Route>
            <Route path="*" element={<NotFound></NotFound>}></Route>
          </Route>
          <Route path={`/preview/${config.webcastId}`} element={<Priview status="post" warmup={true} />}></Route>
          <Route path="*" element={<NotFound></NotFound>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
