/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint no-param-reassign: ["error", { "props": false }] */
import { useRef, useLayoutEffect, useState, useCallback, useMemo, useEffect } from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { isWeiXin, IS_IOS, createUuid } from '@/utils';
import { PauseSvg } from '@/components/svgCom/Pause';
import { PlaySvg } from '@/components/svgCom/paly';
import { Loading } from '@/components/loading/Loading';
import BackFast from '@/components/liveAndPlayback/audioCom/BackFast';
import BackIcon from '@/components/svgCom/Back';
import { AudioVolume } from './AudioVolume';
import AudioMarkItem from './AudioMarkIItem';
import { Contents } from '../Content';
import aegisUtils from '@/utils/aegisReport';
import './audioCom.css';
import './common.css';
export interface MarkList {
  time: string | number;
  text: string;
  id: string;
  isEdit?: boolean;
  isAdd?: boolean;
  [key: string]: any;
}
export const AudioCom = ({ src, markList }: { src?: string; markList: MarkList[] }) => {
  const [audioBtnIconSrc, setAudioIconState] = useState(false);
  const [audioMarkList, setAudioMarkList] = useState<MarkList[]>([]);
  const [processWidth, setProcessWidth] = useState(0);
  const isMobileIosStatus = useRef(false);
  const [timeTotal, setTimeTotal] = useState(0);
  const [audioState, setAudioState] = useState(false);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [audioTotalDuration, setAudioTotalDuration] = useState('0:00');
  const [playWidth, setPlayWidth] = useState('0%');
  const [barLeft, setBarLeft] = useState(isMobile ? 1 : 0);
  const [bubbleVisible] = useState(false);
  const [scaleNum, setScaleNum] = useState(0);
  const [tootipStatus, setTootipStatus] = useState(false);
  const progessRef = useRef(null);
  const audioRef = useRef(null);
  const volumeRef = useRef(null);
  const weixin = isWeiXin();
  const barRef = useRef(null);
  const timeOutId = useRef(null);
  const { aegis } = aegisUtils();
  const options = useRef({
    left: 0,
    right: 0,
    halfBarWidth: 0,
    width: 0,
  });
  const [toolipText, setToolipText] = useState('Backfoward for 10s');
  const playAndPauseHandle = (e) => {
    const audioDom = audioRef.current;
    if (!audioState) return;
    if (!audioDom) return;
    aegis.reportEvent({
      name: '播放暂停按钮', // 必填
      ext1: `音频开始${audioDom.paused === true ? '播放' : '暂停'}`,
    });
    if (audioDom.paused) {
      audioDom.play?.();
      setAudioIconState(true);
    } else {
      audioDom.pause?.();
      setAudioIconState(false);
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    return false;
  };
  useLayoutEffect(() => {
    const lists = markList.reduce((acc, cur) => {
      const item = {
        ...cur,
        isEdit: false,
        id: createUuid(),
        isAdd: false,
      };
      acc.push(item);
      return acc;
    }, []);
    setAudioMarkList(lists);
  }, [markList]);
  useLayoutEffect(() => {
    if (weixin && IS_IOS) {
      // 主动播放，触发durationchange，canplay事件
      audioRef.current.muted = true;
      const audioPro = audioRef.current.play();
      if (audioPro !== undefined) {
        audioPro
          .then(() => {
            audioRef.current.pause();
            volumeRef.current?.applyAudio();
          })
          .catch(() => {
            audioRef.current.pause();
            volumeRef.current?.applyAudio();
          });
      }
      if (audioPro === undefined) {
        audioRef.current.pause();
        volumeRef.current?.applyAudio();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => {
    const progessDom = progessRef.current;
    let isMouseDowm = false;
    let isEnter = false;
    let isPlaying = true;
    const downHandle = (event) => {
      setScaleNum(1);
      if (!audioState) return;
      isMouseDowm = true;
      const react: { width: number } = barRef.current.getBoundingClientRect();
      const barWidth = react.width;
      const halfBarWidth = Math.floor(barWidth / 2);
      const { left, width, right } = progessDom.getBoundingClientRect();
      options.current.halfBarWidth = halfBarWidth;
      options.current.left = left;
      options.current.right = right;
      options.current.width = width;
      const clientX = event.clientX ?? event.touches[0].clientX;
      const duration = clientX - left - halfBarWidth;
      const percentage = (clientX - left) / width;
      const fiexdOne = Number(percentage.toFixed(4)) * 100;
      const progessWidth = `${fiexdOne}%`;
      setPlayWidth(progessWidth);
      setBarLeft(duration);
      if (isSafari) {
        if (audioRef.current.paused) {
          audioRef.current.play();
          audioRef.current.pause();
          isMobileIosStatus.current = true;
        } else {
          isMobileIosStatus.current = false;
        }
        const timeOut = setTimeout(() => {
          audioRef.current.currentTime = audioRef.current.duration * percentage;
          clearInterval(timeOut);
        }, 500);
      } else {
        audioRef.current.currentTime = audioRef.current.duration * percentage;
      }
      if (!audioRef.current.paused && isPlaying) {
        isPlaying = false;
        audioRef.current.pause();
        setAudioIconState(false);
      }
      // 禁止事件冒泡
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      // event.preventDefault();
    };
    const tocuhMoveHandle = (event) => {
      if (!isMouseDowm) return false;
      const clientX = event.clientX ?? event.touches[0].clientX;
      let barLeft = clientX - options.current.left - options.current.halfBarWidth;
      const percentage = (clientX - options.current.left) / options.current.width;
      const fiexdOne = Number(percentage.toFixed(4)) * 100;
      let progessWidth = `${fiexdOne}%`;
      if (clientX - options.current.left < options.current.halfBarWidth) {
        barLeft = 0;
        progessWidth = '0%';
      } else if (clientX - options.current.left > options.current.width - options.current.halfBarWidth) {
        barLeft = options.current.width - options.current.halfBarWidth * 2;
        progessWidth = '100%';
      }
      audioRef.current.currentTime = audioRef.current.duration * percentage;
      setPlayWidth(progessWidth);
      setBarLeft(barLeft);
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      event.preventDefault();
      return false;
    };

    const upHandle = (event) => {
      if (!isMouseDowm) return false;
      aegis.reportEvent({
        name: '音频轨道拖拽', // 必填
        ext1: `当前时间：${audioRef.current.currentTime}`,
        ext2: `当前音量：${audioRef.current.volume}`,
      });
      if (!isEnter && !isMobile) {
        // 鼠标抬起并且离开进度条
        setScaleNum(0);
      }
      if (audioRef.current.paused && !isPlaying) {
        audioRef.current.play?.();
        isPlaying = true;
        setAudioIconState(true);
      }
      isMouseDowm = false;
      event.preventDefault();
    };
    // 鼠标按下时
    progessDom.removeEventListener('mousedown', downHandle, false);
    progessDom.addEventListener('mousedown', downHandle, false);
    progessDom.removeEventListener('touchstart', downHandle, false);
    progessDom.addEventListener('touchstart', downHandle, false);
    // 鼠标移动
    document.removeEventListener('mousemove', tocuhMoveHandle);
    document.addEventListener('mousemove', tocuhMoveHandle, { passive: false });
    document.removeEventListener('touchmove', tocuhMoveHandle);
    document.addEventListener('touchmove', tocuhMoveHandle, { passive: false });
    // 鼠标抬起
    document.removeEventListener('mouseup', upHandle, false);
    document.addEventListener('mouseup', upHandle, { passive: false });
    document.removeEventListener('touchend', upHandle, false);
    document.addEventListener('touchend', upHandle, { passive: false });

    const enterHandle = () => {
      setScaleNum(1);
      isEnter = true;
    };
    const leaveHandle = () => {
      isEnter = false;
      if (isMouseDowm) return; // 鼠标一直没有松开
      setScaleNum(0);
    };
    progessDom.addEventListener('mouseenter', enterHandle, false);
    progessDom.addEventListener('mouseleave', leaveHandle, false);
    return () => {
      progessDom.removeEventListener('mousedown', downHandle, false);
      progessDom.removeEventListener('touchstart', downHandle, false);
      progessDom.removeEventListener('mouseenter', enterHandle, false);
      progessDom.removeEventListener('mouseleave', leaveHandle, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, weixin, audioState]);
  const setAudioTime = useCallback((time: number) => {
    let hourText = '00';
    let minuteText = '';
    let secondText = '';
    const hours = time / 3600;
    if (hours > 1) {
      const hour = parseInt(String(hours), 10);
      if (hour < 10) {
        hourText = `0${String(hour)}`;
      } else {
        hourText = String(hour);
      }
    }
    if (time > 60) {
      const minutes = time / 60;
      const residue = parseInt(String(minutes % 60), 10);
      if (residue < 10) {
        minuteText = `0${String(residue)}`;
      } else {
        minuteText = String(residue);
      }
    } else {
      minuteText = '00';
    }
    const seconds = time % 60;
    if (seconds < 10) {
      secondText = `0${parseInt(String(seconds), 10)}`;
    } else {
      secondText = String(parseInt(String(seconds), 10));
    }
    const timeText = `${hourText}:${minuteText}:${secondText}`;
    return timeText;
  }, []);
  useLayoutEffect(() => {
    // TODO display=0的时候获取不到宽高
    const barWidth = 6;
    const progessDom = progessRef.current;
    const audioDom = audioRef.current;
    const { width } = progessDom.getBoundingClientRect();
    setProcessWidth(width);
    const durationchange = () => {
      setAudioState(true);
      let hour = '';
      // 可以显示播放时长了
      setTimeTotal(audioDom.duration as number);
      const minutes = parseInt(String(audioDom.duration / 60), 10);
      const durationText = setAudioTime(audioDom.duration);
      if (minutes > 60) {
        hour = String(parseInt(String(minutes / 60), 10));
      }
      const curentText = hour ? '00:00:00' : '00:00';
      setCurrentTime(curentText);
      setAudioTotalDuration(durationText);
    };
    const timeupdate = () => {
      const curentTime = parseInt(String(audioDom.currentTime), 10);
      const totalTime = parseInt(String(audioDom.duration), 10);
      const percentage = curentTime / totalTime;
      const progess = Number(percentage.toFixed(4)) * 100;
      setPlayWidth(`${progess}%`);
      const curentText = setAudioTime(audioDom.currentTime);
      setCurrentTime(curentText);
      let leftW = Number((progess / 100).toFixed(4)) * width;
      if (leftW <= 2 * barWidth) {
        leftW = 0;
      } else {
        leftW -= barWidth;
      }
      if (leftW >= width - barWidth * 2) {
        leftW = width - barWidth * 2;
      }
      setBarLeft(leftW);
      // 监听播放完成，ended 事件不准确
      if (audioDom.currentTime >= audioDom.duration) {
        setAudioIconState(false);
      }
    };
    const resizeHandle = () => {
      const totalWidth = progessRef.current.offsetWidth;
      if (!totalWidth) return;
      const percentageW = playWidth;
      const percentageNum = Number(percentageW.replace('%', '')).toFixed(2);
      if (Number(percentageNum) === 0) {
        return;
      }
      let curWidth = (Number(percentageNum) / 100) * totalWidth;
      if (curWidth >= totalWidth) {
        curWidth = totalWidth - options.current.halfBarWidth;
      } else {
        curWidth -= options.current.halfBarWidth;
      }
      if (curWidth < 0) {
        curWidth = 0;
      }
      setBarLeft(curWidth);
    };
    const seekingHandle = () => {
      setAudioState(false);
    };
    const seekedHandle = () => {
      setAudioState(true);
    };
    audioDom.addEventListener('seeking', seekingHandle);
    audioDom.addEventListener('seeked', seekedHandle);
    audioDom.addEventListener('durationchange', durationchange, { once: true });
    audioDom.addEventListener('timeupdate', timeupdate);
    window.addEventListener('resize', resizeHandle);
    return () => {
      audioDom.removeEventListener('durationchange', durationchange);
      audioDom.removeEventListener('timeupdate', timeupdate);
      audioDom.removeEventListener('seeking', seekingHandle);
      audioDom.removeEventListener('seeked', seekedHandle);
      window.removeEventListener('resize', resizeHandle);
    };
  }, [audioRef, progessRef, weixin, playWidth, options, setAudioTime]);
  const hiddleBubbleHandle = useCallback(() => {
    //
  }, []);
  const markLists = useMemo(() => {
    let lists = [];
    if (!timeTotal || !audioMarkList.length || !processWidth) {
      return lists;
    }
    lists = audioMarkList.reduce((acc, cur) => {
      const { time, ...vals } = cur;
      const left = (Number(time) / timeTotal) * processWidth;
      const item = {
        left,
        time,
        id: String(time),
        isHover: false,
        ...vals,
      };
      acc.push(item);
      return acc;
    }, []);
    return lists;
  }, [audioMarkList, timeTotal, processWidth]);
  const hoverHandle = useCallback((type, ele) => {
    const val = type === 'enter';
    setAudioMarkList((list) => {
      const lists = [...list];
      const index = lists.findIndex((item) => item.id === ele.id);
      if (index > -1) {
        lists[index].isHover = val;
      }
      return lists;
    });
  }, []);
  const backFastClick = useCallback((status: boolean) => {
    if (status === true) {
      setToolipText('Fast Forward for 10s');
    } else {
      setToolipText('Backfoward for 10s');
    }
    const audioDom = audioRef.current;
    aegis.reportEvent({
      name: `${status ? '快进' : '后退'}`, // 必填
      ext1: `当前时间：${audioDom.currentTime}`,
    });
    setTootipStatus(true);
    if (timeOutId.current) {
      clearTimeout(timeOutId.current);
    }
    timeOutId.current = setTimeout(() => {
      setTootipStatus(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return () => {
      if (timeOutId.current) {
        clearTimeout(timeOutId.current);
      }
    };
  }, []);

  return (
    <>
      <audio ref={audioRef} preload="auto" cross-origin-isolated="true">
        <source src={src}></source>
        Your browser does not support the audio element.
      </audio>
      <div className="audio-content">
        <div className="customize-audio">
          {isMobile && (
            <div className="audio-time audio-mobile__time" style={{ paddingLeft: isMobile ? '10px' : '20px' }}>
              <span>{`${currentTime}/${audioTotalDuration}`}</span>
            </div>
          )}
          <div className="audio-progess" ref={progessRef}>
            <div className="progess-list">
              <div className="progess-load"></div>
              <div className="progess-play" style={{ width: playWidth }}></div>
              <div
                className="progess-bar"
                style={{ left: `${barLeft}px`, transform: `scale3d(1, 1, ${scaleNum})` }}
                ref={barRef}
              ></div>
            </div>
          </div>
          {markLists.map((ele, index) => (
            <AudioMarkItem
              processWidth={processWidth}
              style={{ left: `${ele.left}` }}
              key={ele.id + index}
              isAdd={ele.isAdd}
              text={ele.text}
              timeText={setAudioTime(Number(ele.time))}
              hoverHandle={(type) => {
                hoverHandle(type, ele);
              }}
            ></AudioMarkItem>
          ))}
        </div>
        <div className="audio-play-pause" style={{ padding: '0px 20px' }}>
          <div className="audio-layout-left">
            <div
              className="play-pause-btn"
              style={{ width: '16px' }}
              onClick={(e) => {
                if (!isMobile) {
                  playAndPauseHandle(e);
                }
              }}
              onTouchEnd={(e) => {
                if (isMobile) {
                  playAndPauseHandle(e);
                }
              }}
            >
              {!audioState ? <Loading></Loading> : audioBtnIconSrc ? <PlaySvg></PlaySvg> : <PauseSvg></PauseSvg>}
            </div>
            {!isMobile && (
              <div className="audio-time" style={{ paddingLeft: isMobile ? '10px' : '20px' }}>
                <span>{`${currentTime}/${audioTotalDuration}`}</span>
              </div>
            )}
            <BackFast state={audioState} audioRef={audioRef} clickHandle={backFastClick}></BackFast>
          </div>
          <div className="audio-layout-right">
            {markLists?.length > 0 && (
              <Contents
                audioRef={audioRef}
                setAudioTime={setAudioTime}
                list={markLists}
                isMobileIosStatus={isMobileIosStatus}
              ></Contents>
            )}
            <AudioVolume
              isShowBar={!isMobile}
              audioRef={audioRef}
              bubbleVisible={bubbleVisible}
              hideBubble={hiddleBubbleHandle}
              ref={volumeRef}
            ></AudioVolume>
          </div>
        </div>
      </div>
      <div className="title-fixed" style={{ display: tootipStatus ? 'block' : 'none' }}>
        <span>
          <BackIcon isWhite={false} />
        </span>
        <span style={{ paddingLeft: '8px' }}>{toolipText}</span>
      </div>
    </>
  );
};
