/*
 * @Author: princemwang
 * @Date: 2023-04-06 15:27:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-10 10:48:03
 */
const FastIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6666 0L-4.08292e-05 8L10.6666 16V0Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 3.00005L16 0V16L11.9999 13V3.00005Z" fill="black" />
    </svg>
  );
};
export default FastIcon;
