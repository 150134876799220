/*
 * @Author: princemwang
 * @Date: 2022-06-29 18:30:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-07-08 19:56:37
 */
import { getSessionId } from '@/utils';
import { Layout, message } from '@tencent/tea-component';
import { LiveAndPlayBack } from '@/components/liveAndPlayback';
import FooterCom from '@/components/footer/Footer';
import { Navigate, useNavigate } from 'react-router-dom';
import config from '@/config';
import { createInitState } from '@/globalState';
import { useLayoutEffect } from 'react';
import { getLiveStatus } from '@/api/index';
import { Loading } from '@/components/loading/Loading';

const { Content, Body } = Layout;

export const LiveHome = () => {
  const sessionId = getSessionId();
  const [state, setState] = createInitState();
  useLayoutEffect(() => {
    const getStatusHandle = async () => {
      try {
        const { data, errorCode, errorMessage } = await getLiveStatus({ webcastId: config.webcastId, sessionId });
        if (errorCode !== 0) {
          return message.error({ content: errorMessage });
        }
        setState(data);
      } catch (error) {
        console.log(error);
      }
    };
    getStatusHandle();
  }, [setState, sessionId]);
  return (
    <>
      {state.status === 'loading' ? (
        <Loading></Loading>
      ) : (state.status === 'prior' && !state.warmup) || !sessionId ? (
        <Navigate to={`/${config.webcastId}`} replace></Navigate>
      ) : (
        <>
          <Body>
            <Content>
              <Content.Body full className="live">
                <LiveAndPlayBack state={state}></LiveAndPlayBack>
              </Content.Body>
            </Content>
          </Body>
          <FooterCom></FooterCom>
        </>
      )}
    </>
  );
};
