/*
 * @Author: princemwang
 * @Date: 2022-07-01 15:42:39
 * @LastEditors: 映射接口名称
 * @LastEditTime: 2022-07-01 15:48:36
 */
// 通过请求url映射定义的api接口名称
export const castApi = {
  status: 'getStatus',
  doc: 'getDocs',
  guest: 'setGuestInfo',
  stat: 'reportInfo',
};
