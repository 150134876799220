/*
 * @Author: princemwang
 * @Date: 2022-06-22 15:10:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-19 15:33:20
 */
import { PriviewPpt } from '@/components/liveAndPlayback/pptPagination/PreviewPpt';
import { PptImg } from '@/components/liveAndPlayback/livePlaybackNotStart/PptImg';
import { Loading } from '@/components/loading/Loading';

export const LiveAndPlayBack = ({ state }: { state: { status: string; warmup: boolean; stream: string } }) => {
  return (
    <>
      {state.status === 'loading' ? (
        <Loading></Loading>
      ) : state.status === 'prior' || state.status === 'post' ? (
        <PptImg {...state}></PptImg>
      ) : (
        <PriviewPpt {...state}></PriviewPpt>
      )}
    </>
  );
};
