/*
 * @Author: princemwang
 * @Date: 2022-06-24 21:47:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-19 15:08:41
 */
import React, { useState, useRef, useCallback, useLayoutEffect, useImperativeHandle, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Bubble } from '@tencent/tea-component';
import { isWeiXin } from '@/utils';
import { MuteSvg } from '@/components/svgCom/Mute';
import { MutedSvg } from '@/components/svgCom/Muted';
import './audioVolume.css';
import './common.css';
import aegisUtils from '@/utils/aegisReport';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const AudioVolume = React.forwardRef(
  (
    props: {
      audioRef: React.MutableRefObject<any>;
      bubbleVisible?: boolean;
      hideBubble?: () => void;
      isShowBar?: boolean;
    },
    ref,
  ) => {
    const { audioRef, bubbleVisible, hideBubble, isShowBar = true } = props;
    const [volumnSrc, setvolumnSrc] = useState(true);
    const [volumeWidth, setVolumeWidth] = useState('100%');
    const [volumeBarLeft, setVolumeBarLeft] = useState(isMobile ? 48 : 108);
    const [scaleNum, setScaleNum] = useState(isMobile ? 1 : 0);
    const { aegis } = aegisUtils();
    const volumeRef = useRef(null);
    const volumeBarRef = useRef(null);
    const volumeNum = useRef(0);
    const options = useRef({
      left: 0,
      right: 0,
      halfBarWidth: 0,
      width: 0,
    });
    const weixin = isWeiXin();
    const vloumeHandle = useCallback(() => {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      if (audioRef.current.muted) {
        if (volumeNum.current === 0) {
          volumeNum.current = 1;
        }
        audioRef.current.muted = false;
        hideBubble?.();
        audioRef.current.volume = volumeNum.current;
        setvolumnSrc(true);
        const { width } = options.current;
        const leftW = Number(volumeNum.current.toFixed(2)) * width;
        const barWidth = 12;
        setVolumeBarLeft(leftW - barWidth);
        setVolumeWidth(`${Number(volumeNum.current.toFixed(2)) * 100}%`);
      } else {
        audioRef.current.muted = true;
        volumeNum.current = audioRef.current.volume;
        audioRef.current.volume = 0;
        setVolumeBarLeft(0);
        setVolumeWidth('0%');
        setvolumnSrc(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRef, hideBubble]);
    useLayoutEffect(() => {
      const volumeDom = volumeRef.current;
      const volumeBarDom = volumeBarRef.current;
      let isMouseDowm = false;
      const { left, right, width } = volumeDom.getBoundingClientRect();
      options.current.left = left;
      options.current.right = right;
      options.current.width = width;
      const downHandle = (event) => {
        isMouseDowm = true;
        const { left, right, width } = volumeDom.getBoundingClientRect();
        const react: { width: number } = volumeBarDom.getBoundingClientRect();
        options.current.left = left;
        options.current.right = right;
        options.current.width = width;
        options.current.halfBarWidth = Math.floor(react.width / 2);
        const clientX = event.clientX ?? event.touches[0].clientX;
        let barLeft = clientX - left - options.current.halfBarWidth;
        if (barLeft >= width - react.width) {
          barLeft = width - react.width;
        }
        if (clientX - left > 0) {
          hideBubble?.();
        }
        if (barLeft < 0) {
          barLeft = 0;
          setvolumnSrc(false);
          audioRef.current.muted = true;
          audioRef.current.volume = 0;
        } else {
          audioRef.current.muted = false;
          const bai = (clientX - left) / width;
          audioRef.current.volume = Number(bai.toFixed(1));
          setvolumnSrc(true);
        }
        setVolumeBarLeft(barLeft);

        const percentage = (clientX - left) / width;
        const fiexdOne = Number(percentage.toFixed(4)) * 100;
        let progessWidth = `${fiexdOne}%`;
        if (clientX >= right) {
          progessWidth = '100%';
        }
        if (clientX <= left) {
          progessWidth = '0%';
        }
        setVolumeWidth(progessWidth);
        // 禁止事件冒泡
        if (event.stopPropagation) {
          event.stopPropagation();
        }
        event.preventDefault();
      };
      const tocuhMoveHandle = (event) => {
        if (!isMouseDowm) return false;
        const clientX = event.clientX ?? event.touches[0].clientX;
        let barLeft = clientX - options.current.left - options.current.halfBarWidth;
        const percentage = (clientX - options.current.left) / options.current.width;
        let progessW = Number(percentage.toFixed(4)) * 100;
        if (clientX - options.current.left < options.current.halfBarWidth) {
          barLeft = 0;
          audioRef.current.muted = true;
          audioRef.current.volume = 0;
          setvolumnSrc(false);
        } else if (clientX - options.current.left > options.current.width - options.current.halfBarWidth) {
          barLeft = options.current.width - options.current.halfBarWidth * 2;
          audioRef.current.volume = 1;
          audioRef.current.muted = false;
          setvolumnSrc(true);
        } else {
          const volumeNum = Number(percentage.toFixed(1));
          audioRef.current.volume = volumeNum;
          audioRef.current.muted = false;
          setvolumnSrc(true);
        }
        if (progessW <= 0) {
          progessW = 0;
        }
        if (progessW >= 100) {
          progessW = 100;
        }
        setVolumeWidth(`${progessW}%`);
        setVolumeBarLeft(barLeft);
        if (event.stopPropagation) {
          event.stopPropagation();
        }
        // event.preventDefault();
      };
      const upHandle = () => {
        if (!isMouseDowm) return false;
        isMouseDowm = false;
      };
      // 鼠标按下
      volumeDom.removeEventListener('mousedown', downHandle, false);
      volumeDom.addEventListener('mousedown', downHandle, false);
      volumeDom.removeEventListener('touchstart', downHandle, { capture: true });
      volumeDom.addEventListener('touchstart', downHandle, { capture: true });
      // 鼠标移动
      document.removeEventListener('mousemove', tocuhMoveHandle, false);
      document.addEventListener('mousemove', tocuhMoveHandle, false);
      document.removeEventListener('touchmove', tocuhMoveHandle, { capture: true });
      document.addEventListener('touchmove', tocuhMoveHandle, { capture: true });
      // 鼠标抬起
      document.removeEventListener('mouseup', upHandle, false);
      document.addEventListener('mouseup', upHandle, false);
      document.removeEventListener('touchend', upHandle, { capture: true });
      document.addEventListener('touchend', upHandle, { capture: true });
      const enterHandle = () => {
        setScaleNum(1);
      };
      const leaveHandle = () => {
        if (isMouseDowm) return; // 鼠标一直没有松开
        if (!isMobile) {
          setScaleNum(0);
        }
      };
      volumeDom.addEventListener('mouseenter', enterHandle, false);
      volumeDom.addEventListener('mouseleave', leaveHandle, false);
      // audioRef.current.addEventListener('canplay', function () {
      //   // 音频可以播放了
      //   const { width } = volumeDom.getBoundingClientRect();
      //   const { volume } = audioRef.current;
      //   console.log(volume, 'volume');
      //   const leftW = Number(volume.toFixed(2)) * width;
      //   const barWidth = 12;
      //   let barLeft = leftW - barWidth;
      //   if (volume === 0) {
      //     barLeft = 0;
      //   }
      //   setVolumeBarLeft(barLeft);
      //   setVolumeWidth(`${Number(volume.toFixed(2)) * 100}%`);
      // });
      return () => {
        document.removeEventListener('mouseup', upHandle, false);
        document.removeEventListener('touchend', upHandle, { capture: true });
        document.removeEventListener('touchmove', tocuhMoveHandle, { capture: true });
        document.removeEventListener('mousemove', tocuhMoveHandle, false);
        volumeDom.removeEventListener('mousedown', downHandle, false);
        volumeDom.removeEventListener('touchstart', downHandle, { capture: true });
      };
    }, [audioRef, hideBubble]);
    const muteVolume = useCallback(() => {
      setVolumeBarLeft(0);
      setVolumeWidth('0%');
      setvolumnSrc(false);
      audioRef.current.volume = 0;
      audioRef.current.muted = true;
    }, [audioRef]);
    const applyAudio = useCallback(() => {
      setVolumeBarLeft(114);
      setVolumeWidth('100%');
      setvolumnSrc(true);
      audioRef.current.muted = false;
      audioRef.current.volume = 1;
    }, [audioRef]);
    useImperativeHandle(ref, () => ({
      muteVolume,
      applyAudio,
    }));
    const audioDom = audioRef.current;
    useEffect(() => {
      if (!audioDom) return;
      const volumechange = () => {
        aegis.reportEvent({
          name: '音量调节', // 必填
          ext1: `当前音量为${audioRef.current.volume}`,
        });
      };
      audioDom.addEventListener('volumechange', volumechange);
      return () => {
        audioDom.removeEventListener('volumechange', volumechange);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioDom]);
    return (
      <div className="volume">
        <div className="volume-icon">
          {!weixin && !isMobile ? (
            <Bubble
              onClick={vloumeHandle}
              trigger={'empty'}
              placement="top"
              content={<div>Click to turn on sound</div>}
              visible={bubbleVisible}
              className="audio-bubble__inner"
            >
              {!volumnSrc ? <MuteSvg click={vloumeHandle}></MuteSvg> : <MutedSvg click={vloumeHandle}></MutedSvg>}
            </Bubble>
          ) : (
            <>{!volumnSrc ? <MuteSvg click={vloumeHandle}></MuteSvg> : <MutedSvg click={vloumeHandle}></MutedSvg>}</>
          )}
        </div>
        <div
          className="volume-progess"
          style={{ width: isMobile ? '60px' : '120px', display: isShowBar ? 'block' : 'none' }}
        >
          <div className="audio-progess" ref={volumeRef}>
            <div className="progess-list">
              <div className="progess-load"></div>
              <div className="progess-play" style={{ width: volumeWidth }}></div>
              <div
                className="progess-bar"
                style={{ left: `${volumeBarLeft}px`, transform: `scale3d(1, 1, ${scaleNum})` }}
                ref={volumeBarRef}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
AudioVolume.displayName = 'AudioVolume';
