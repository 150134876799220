/*
 * @Author: princemwang
 * @Date: 2022-07-07 15:47:11
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-03 15:13:55
 */
import { getDocs } from '@/api';
import { useState, useMemo, useLayoutEffect, useCallback, useEffect } from 'react';
import config from '@/config';
import { getSessionId, IS_IOS, tmeTimeString, IS_GROUP } from '@/utils';
import { isMobile } from 'react-device-detect';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { FullScreenSvg } from '@/components/svgCom/FullScreen';
import { createIosFullScreen, createInitFormText } from '@/globalState';
import { useIsHorizontalScreen, useIsFullScreen } from '@/hooks';
import './pptImg.css';

export const PptImg = ({ warmup, status }: { warmup: boolean; status: string }) => {
  const screen = useFullScreenHandle();
  const [formText] = createInitFormText();
  const time = formText.startUtc;
  const isFullScreen = useIsFullScreen();
  const sessionId = getSessionId();
  const [img, setImg] = useState('');
  const [screenState, setScreenState] = useState(false);
  const [, setIosFullScreen] = createIosFullScreen();
  const isHorizontalScreen = useIsHorizontalScreen();
  const [parentWidth, setParentWidth] = useState('auto');
  const getImgs = useCallback(async () => {
    try {
      const { data } = await getDocs({ webcastId: config.webcastId, sessionId });
      const img = data.slides[0].url;
      setImg(img);
    } catch (error) {
      console.log(error);
    }
  }, [sessionId]);
  const statusText = useMemo(() => {
    let text = '';
    if (status === 'prior') {
      text = IS_GROUP
        ? `The webcast will start ${tmeTimeString(time)}`
        : 'The webcast will start at 8:00 am EDT / 8:00 pm HKT.';
    } else {
      text = 'The replay will be ready soon.';
    }
    return text;
  }, [status, time]);
  useLayoutEffect(() => {
    getImgs();
  }, [getImgs]);
  const fullScreenHandle = useCallback(() => {
    if (IS_IOS) {
      setIosFullScreen((val) => !val);
      if (screenState) {
        setScreenState(false);
      } else {
        setScreenState(true);
      }
      return false;
    }
    if (screen.active) {
      screen.exit();
    } else {
      screen.enter();
    }
  }, [screen, setScreenState, screenState, setIosFullScreen]);
  useEffect(() => {
    return () => {
      setIosFullScreen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isFullScreen) {
      setScreenState(false);
    } else {
      setScreenState(true);
    }
  }, [isFullScreen]);
  useEffect(() => {
    if (!isMobile) return;
    if (isHorizontalScreen && screenState) {
      setParentWidth('auto');
    } else if (isHorizontalScreen && !screenState) {
      setParentWidth('auto');
    } else {
      setParentWidth('100vw');
    }
  }, [screenState, isHorizontalScreen]);
  const imgMaxHeight = useMemo(() => {
    let height = '';
    if (isMobile) {
      if (isHorizontalScreen) {
        if (screenState) {
          height = 'calc(var(--vh) * 100)';
        } else {
          height = 'calc(var(--vh) * 100 - 180px)';
        }
      } else {
        height = 'calc(100vh - 200px)';
      }
    } else {
      if (screenState) {
        height = '100%';
      } else {
        height = 'calc(100vh - 200px)';
      }
    }
    return height;
  }, [isHorizontalScreen, screenState]);
  const imgWidth = useMemo(() => {
    let width = '';
    if (screenState && !isHorizontalScreen) {
      width = '100vw';
    } else {
      width = 'auto';
    }
    if (!isMobile && screenState) {
      width = '100vw';
    }
    return width;
  }, [screenState, isHorizontalScreen]);
  const addIosClass = useMemo(() => {
    const iosClass = screenState && IS_IOS && isMobile ? 'ios-img-parent' : '';
    return `ppt-img ${iosClass}`;
  }, [screenState]);
  const swiperParentWidth = useMemo(() => {
    let width = '';
    if (isMobile) {
      if (isHorizontalScreen) {
        width = parentWidth;
      } else {
        width = '100vw';
      }
    } else {
      width = 'auto';
    }
    return width;
  }, [isHorizontalScreen, parentWidth]);
  return (
    <FullScreen handle={screen}>
      {img && (
        <div className={'ppt-img'} style={{ width: swiperParentWidth, minWidth: isMobile ? '100vw' : '540px' }}>
          <img
            src={img}
            alt=""
            id="warm-img"
            style={{
              maxWidth: isMobile ? '100vw' : screenState ? '100%' : 'calc(100vw - 200px)',
              maxHeight: imgMaxHeight,
              width: imgWidth,
            }}
          />
          {!warmup && (
            <div className="mask">
              <div className="img-mask"></div>
              <div className="mask-content">
                <span
                  className="mask-title"
                  style={{
                    fontSize: isMobile ? '18px' : '28px',
                    padding: isMobile ? '14px 30px' : '20px 60px',
                    lineHeight: isMobile ? '27px' : '42px',
                  }}
                >
                  {statusText}
                </span>
              </div>
            </div>
          )}
          {warmup && (
            <div className="warmup-tar">
              <div className="page-content page-pre">
                <div className="page-pre-btn"></div>
              </div>
              <div className="page-content page-next">
                <div className="page-next-btn"></div>
              </div>
              <div className="page-footer">
                <FullScreenSvg handleClick={fullScreenHandle} state={screenState}></FullScreenSvg>
              </div>
            </div>
          )}
        </div>
      )}
    </FullScreen>
  );
};
