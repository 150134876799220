/*
 * @Author: princemwang
 * @Date: 2022-06-22 10:38:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-18 16:47:39
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { request } from '@/request';
import { Status, ReportInfo, VerifyWebcastId, RequestType } from '@/request/reqType';
import axios, { AxiosRequestConfig } from 'axios';

/**
 * @description 登记访客信息
 * @param data
 * @returns
 */
export const setGuestInfo = <T extends keyof RequestType>(data: RequestType[T]) => {
  return request<'guest'>({
    url: '/webcast/v1/guest/register',
    method: 'post',
    data,
  });
};
/**
 * @description 获取直播状态
 */
export const getLiveStatus = (data: Status) => {
  return request<'status'>({
    url: '/webcast/v1/status/query',
    method: 'post',
    data,
  });
};
/**
 * @description 获取ppt图片列表
 * @param params
 * @returns
 */
export const getDocs = (data: Status) => {
  return request<'doc'>({
    url: '/webcast/v1/doc/query',
    method: 'post',
    data,
  });
};
/**
 * @description 统计客户端信息
 * @param data
 * @returns
 */
export const reportInfo = (data: ReportInfo) => {
  return request<'report'>({
    url: '/webcast/v1/stat/update',
    method: 'post',
    data,
  });
};
/**
 * @description 验证webcastId
 * @param data
 * @returns
 */
export const verifyWebcast = (data: VerifyWebcastId) => {
  return request<'verify'>({
    url: '/webcast/v1/webcast/verify',
    method: 'post',
    data,
  });
};
/**
 * @description 排队
 * @param body
 * @returns
 */
export const enqueue = async (body: any) => {
  const res = await axios.post('https://test12.rtc.qq.com:3000/Enqueue', body);
  return res?.data;
};
/**
 * @description 启动应用
 * @param body
 * @returns
 */
export const startProject = async (body: any) => {
  const res = await axios.post('https://test12.rtc.qq.com:3000/StartProject', body);
  return res?.data;
};
