export const FullScreenSvg = ({ handleClick, state }: { handleClick: () => void; state?: boolean }) => {
  return (
    <div onClick={handleClick} style={{ lineHeight: 0 }}>
      {state ? (
        <svg
          className="fullscreen-icon"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4993 0.891897V3.56758C11.4993 5.04531 12.6973 6.24325 14.175 6.24325H16.8507C17.3433 6.24325 17.7426 5.84394 17.7426 5.35136C17.7426 4.85878 17.3433 4.45947 16.8507 4.45947H14.175C13.6824 4.45947 13.2831 4.06015 13.2831 3.56757V0.891895C13.2831 0.399317 12.8838 1.78814e-07 12.3912 1.78814e-07C11.8986 1.78814e-07 11.4993 0.399318 11.4993 0.891897ZM0.891407 6.24325H3.56709C5.04482 6.24325 6.24276 5.04531 6.24276 3.56757V0.891893C6.24276 0.399316 5.84345 0 5.35087 0C4.85829 0 4.45898 0.399313 4.45898 0.891893V3.56757C4.45898 4.06015 4.05967 4.45947 3.56709 4.45947H0.891407C0.398829 4.45947 -0.000488281 4.85878 -0.000488281 5.35136C-0.000488281 5.84394 0.398827 6.24325 0.891407 6.24325ZM6.24325 16.8514V14.1757C6.24325 12.6979 5.04531 11.5 3.56757 11.5H0.891891C0.399314 11.5 0 11.8993 0 12.3919C0 12.8845 0.399312 13.2838 0.891891 13.2838H3.56757C4.06015 13.2838 4.45947 13.6831 4.45947 14.1757V16.8514C4.45947 17.3439 4.85878 17.7433 5.35136 17.7433C5.84393 17.7433 6.24325 17.3439 6.24325 16.8514ZM16.8507 11.5H14.175C12.6973 11.5 11.4993 12.6979 11.4993 14.1757V16.8514C11.4993 17.3439 11.8986 17.7433 12.3912 17.7433C12.8838 17.7433 13.2831 17.3439 13.2831 16.8514V14.1757C13.2831 13.6831 13.6824 13.2838 14.175 13.2838H16.8507C17.3433 13.2838 17.7426 12.8845 17.7426 12.3919C17.7426 11.8993 17.3433 11.5 16.8507 11.5Z"
            fill="black"
          />
        </svg>
      ) : (
        <svg
          className="fullscreen-icon"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 13.4834V16.1591C0 17.6369 1.19794 18.8348 2.67568 18.8348H5.35136C5.84394 18.8348 6.24325 18.4355 6.24325 17.9429C6.24325 17.4503 5.84394 17.051 5.35136 17.051H2.67568H2.67568C2.1831 17.051 1.78379 16.6517 1.78379 16.1591V13.4834C1.78379 12.9909 1.38447 12.5915 0.891894 12.5915C0.399316 12.5915 0 12.9909 0 13.4834V13.4834ZM12.4865 18.8348H15.1622C16.6399 18.8348 17.8379 17.6369 17.8379 16.1591V13.4834C17.8379 12.9909 17.4385 12.5915 16.946 12.5915C16.4534 12.5915 16.0541 12.9909 16.0541 13.4834V16.1591C16.0541 16.6517 15.6548 17.051 15.1622 17.051H12.4865C11.9939 17.051 11.5946 17.4503 11.5946 17.9429C11.5946 18.4355 11.9939 18.8348 12.4865 18.8348H12.4865ZM17.8379 6.34831V3.67263C17.8379 2.19489 16.6399 0.99695 15.1622 0.99695H12.4865C11.9939 0.99695 11.5946 1.39626 11.5946 1.88884C11.5946 2.38142 11.9939 2.78074 12.4865 2.78074H15.1622C15.6548 2.78074 16.0541 3.18005 16.0541 3.67263V6.34831V6.34831C16.0541 6.84089 16.4534 7.2402 16.946 7.2402C17.4385 7.2402 17.8379 6.84089 17.8379 6.34831V6.34831ZM5.35136 0.996948H2.67568C1.19795 0.996948 2.03048e-06 2.19489 2.03048e-06 3.67263V6.34831C2.03048e-06 6.84089 0.399316 7.2402 0.891896 7.2402C1.38447 7.2402 1.78379 6.84089 1.78379 6.34831V3.67263V3.67263C1.78379 3.18005 2.1831 2.78074 2.67568 2.78074H5.35136C5.84394 2.78074 6.24325 2.38142 6.24325 1.88884C6.24325 1.39626 5.84394 0.996948 5.35136 0.996948H5.35136Z"
            fill="black"
          />
        </svg>
      )}
    </div>
  );
};
