/*
 * @Author: princemwang
 * @Date: 2022-06-29 16:31:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-29 16:37:49
 */
const NotFound = () => {
  return (
    <>
      <h2>Please use the correct link to access the webcast</h2>
    </>
  );
};
export default NotFound;
