/*
 * @Author: princemwang
 * @Date: 2022-06-29 10:02:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-01 11:07:58
 */
import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { verifyWebcast, setGuestInfo, getLiveStatus } from '@/api';
import config from '@/config';
import { RequestType } from '@/request/reqType';
import { message } from '@tencent/tea-component';
import Cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export function createGlobalState(initialState) {
  const store = {
    state: initialState,
    setState: (state) => {
      store.state = state;
      store.setters.forEach(function (setter) {
        return setter(store.state);
      });
    },
    setters: [],
  };
  return function () {
    const state = useState(store.state);
    const globalState = state[0];
    const stateSetter = state[1];
    useEffect(function () {
      return function () {
        store.setters = store.setters.filter(function (setter) {
          return setter !== stateSetter;
        });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useLayoutEffect(function () {
      if (!store.setters.includes(stateSetter)) {
        store.setters.push(stateSetter);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return [globalState, store.setState];
  };
}
/**
 * @description 是否横屏
 * @returns
 */
export const useIsHorizontalScreen = () => {
  const innerW = window.innerWidth;
  const innerH = window.innerHeight;
  const [isHorizontalScreen, setHorizontalScreen] = useState(innerW > innerH);
  useEffect(() => {
    const orientationchange = () => {
      if (window.orientation === 180 || window.orientation === 0) {
        setHorizontalScreen(false);
      }
      if (window.orientation === 90 || window.orientation === -90) {
        setHorizontalScreen(true);
      }
    };
    orientationchange();
    const handleType = 'onorientationchange' in window ? 'orientationchange' : 'resize';
    window.addEventListener(handleType, orientationchange, false);
    return () => {
      window.removeEventListener(handleType, orientationchange, false);
    };
  }, []);
  return isHorizontalScreen;
};
/**
 * @description 是否全屏
 * @returns
 */
export const useIsFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    const handle = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const wDocument = window.document as any;
      if (!wDocument.webkitIsFullScreen && !wDocument.mozFullScreen && !wDocument.msFullscreenElement) {
        // 退出全屏
        setIsFullScreen(false);
      } else {
        setIsFullScreen(true);
      }
    };
    document.addEventListener('webkitfullscreenchange', handle);
    document.addEventListener('mozfullscreenchange', handle);
    document.addEventListener('fullscreenchange', handle);
    document.addEventListener('MSFullscreenChange', handle);
    return () => {
      document.removeEventListener('webkitfullscreenchange', handle);
      document.removeEventListener('mozfullscreenchange', handle);
      document.removeEventListener('fullscreenchange', handle);
      document.removeEventListener('MSFullscreenChange', handle);
    };
  }, []);
  return isFullScreen;
};
export const useGetUtcTime = (isWarm) => {
  const [serverUtc, setServerUtc] = useState('');
  useEffect(() => {
    const getServerUtc = async () => {
      try {
        const { data, errorCode } = await verifyWebcast({ webcastId: config.webcastId });
        if (errorCode !== 0) {
          return false;
        }
        setServerUtc(data.serverUtc);
      } catch (error) {
        console.log(error);
      }
    };
    if (isWarm) {
      getServerUtc();
    }
  }, [isWarm]);
  return serverUtc;
};
export const useSubmitForm = <T extends keyof RequestType>(showModay: () => void) => {
  const navigate = useNavigate();
  return useCallback(
    async (values) => {
      try {
        const datas: RequestType[T] = {
          ...values,
          webcastId: config.webcastId,
        };
        const res = await setGuestInfo(datas);
        const { sessionId } = res.data;
        if (!sessionId) {
          return message.error({ content: 'Network error, please try again later.' });
        }
        Cookie.set(config.webcastId, sessionId, { expires: 90 });
        localStorage.setItem(config.webcastId, JSON.stringify(values));
        const { errorCode, data } = await getLiveStatus({ webcastId: config.webcastId, sessionId });
        if (errorCode !== 0) return false;
        if (data.status === 'prior' && !data.warmup) {
          showModay();
        } else {
          navigate(`/live/${config.webcastId}`, {
            replace: true,
          });
        }
      } catch (error) {
        message.error({ content: 'Network error, please try again later.' });
        console.log(error);
      }
    },
    [showModay, navigate],
  );
};
