/*
 * @Author: princemwang
 * @Date: 2022-06-22 15:20:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-16 15:12:17
 */
import { Layout } from '@tencent/tea-component';
import { AudioCom } from '@/components/liveAndPlayback/audioCom/AudioCom';
import { NoStart } from '@/components/liveAndPlayback/livePlaybackNotStart/Nostart';
import { LiveIng } from '@/components/liveAndPlayback/liveing/LiveIng';
import { createInitState, createIosFullScreen } from '@/globalState';
import { useIsHorizontalScreen } from '@/hooks';
import { useMemo } from 'react';

const { Footer } = Layout;

const FooterCom = () => {
  const [state] = createInitState();
  const [iosFullScreen] = createIosFullScreen();
  const isHorizontalScreen = useIsHorizontalScreen();
  const showVolum = ['prior', 'post'];
  const markList = useMemo(() => {
    return state?.keyMark?.list || [];
  }, [state]);
  return (
    <div id="footer-content">
      <Footer
        className={isHorizontalScreen ? '' : 'bottom-button'}
        style={{ display: iosFullScreen ? 'none' : 'block' }}
      >
        {showVolum.includes(state.status) ? (
          <NoStart src={state.music} {...state}></NoStart>
        ) : state.status === 'vod' ? (
          <AudioCom src={state.vod} markList={markList}></AudioCom>
        ) : state.status === 'live' ? (
          <LiveIng src={state.stream}></LiveIng>
        ) : null}
      </Footer>
    </div>
  );
};
export default FooterCom;
