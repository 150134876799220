/*
 * @Author: princemwang
 * @Date: 2023-03-29 10:38:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-18 18:27:32
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import './style.css';
import { message } from '@tencent/tea-component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { enqueue, startProject } from '@/api';
import loading from '@/assets/imgs/loading.png';

declare global {
  interface Window {
    TCGSDK: any;
  }
}
const VirtualPage = () => {
  const [indexPerson, setIndexPerson] = useState('1');
  const [startDisplay, setStartDisplay] = useState(true);
  const [queueDisplay, setQueueDisplay] = useState(false);
  const reqData = useMemo(() => {
    return {
      UserId: `user-${Math.floor(Math.random() * 1000)}`,
      ProjectId: 'cap-c9kez76m',
      RequestId: `request-${Math.floor(Math.random() * 1000)}`, // 随机一个request id
    };
  }, []);
  const startProjectHandle = useCallback(async () => {
    try {
      const data = {
        ...reqData,
        ClientSession: window.TCGSDK.getClientSession(),
      };
      const resData = await startProject(data);
      const { Code, SessionDescribe } = resData;
      if (Code === 0) {
        setStartDisplay(false);
        window.TCGSDK.start(SessionDescribe?.ServerSession);
      } else {
        setStartDisplay(false);
        message.error({
          content: '云渲染加载失败',
        });
        // 异常处理逻辑
      }
    } catch (error) {
      setStartDisplay(false);
      console.log(error);
      message.error({
        content: '云渲染加载失败',
      });
    }
  }, [reqData]);
  const enqueueHandle = useCallback(async () => {
    try {
      const data = await enqueue(reqData);
      const { Code, Data } = data;
      // 排队中
      if (Code === 10100) {
        const { Index } = Data;
        setQueueDisplay(true);
        setStartDisplay(false);
        setIndexPerson(Index);
        // document.querySelector('.loading-queue-index').innerHTML = `当前第 ${Index} 位...`;
        // 5s 轮训一次队列
        setTimeout(() => {
          enqueueHandle();
        }, 5000);
      }

      // 排队成功
      if (Code === 10101) {
        setQueueDisplay(false);
        startProjectHandle();
      }
    } catch (error) {
      console.log(error);
    }
  }, [reqData, startProjectHandle]);
  useEffect(() => {
    window.TCGSDK.init({
      mount: 'virtual',
      debugSetting: {
        showLog: true,
      },
      showLoading: false,
      // 连接成功回调
      onConnectSuccess: (res) => {
        console.log('onConnectSuccess', res);
      },
      // 网络中断/被踢触发此回调
      onDisconnect: (res) => {
        console.log('onDisconnect', res);
      },
      // onTouch 回调，res 为 object[]
      // 移动端上操作端游应用，通过touch 模拟端游的鼠标操作
      onTouchEvent: (res) => {
        // 如果是手游方案，不需要这段逻辑，SDK 内部已经处理
        const { id, type, pageX, pageY } = res.pop();
        // 调用鼠标移动
        window.TCGSDK.mouseMove(id, type, pageX, pageY);
        // 模拟鼠标点击与抬起
        if (type === 'touchstart') {
          window.TCGSDK.sendMouseEvent({ type: 'mouseleft', down: true });
        }
        if (type === 'touchend' || type === 'touchcancel') {
          window.TCGSDK.sendMouseEvent({ type: 'mouseleft', down: false });
        }
      },
      onWebrtcStatusChange: (res) => {
        console.log('onWebrtcStatusChange', res);
      },
      onInitSuccess: async (res) => {
        console.log('%c onInitSuccess', 'color: red', res);
        const { code } = res;
        if (code === 0) {
          enqueueHandle();
        }
      },
    });
  }, [enqueueHandle]);
  return (
    <>
      <div className="virtual-content virtual-content__queue" id="virtual"></div>
      <div
        className={isMobile ? 'loading-layout loading-layout__mobile' : 'loading-layout loading-layout__pc'}
        style={{ display: startDisplay ? 'block' : 'none' }}
      >
        <div className="loading-layout__title">
          <img src={loading} alt="" className="loading-png" />
          <span className="loading-layout_text">Start cloud rendering...</span>
        </div>
      </div>
      <div
        className={isMobile ? 'queue-layout queue-layout__mobile' : 'queue-layout queue-layout__pc'}
        style={{ display: queueDisplay ? 'block' : 'none' }}
      >
        <div className="queue-layout__flex">
          <img src={loading} alt="" className="loading-png" />
          <div className="queue-layout__content">The number of participants has reached the maximum, please wait</div>
          <div className="queue-layout__footer">
            <span>Queue number</span>
            <span>{`${indexPerson} person`}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default VirtualPage;
