/*
 * @Author: princemwang
 * @Date: 2022-06-22 15:05:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-23 15:07:32
 */
import GroupHeader from '@/components/header/groupHeader/Header';
import TmeHeader from '@/components/header/tmeHeader/Header';
import HuyaHeader from '@/components/header/huyaHeader/Header';
import { IS_GROUP, IS_HUYA } from '@/utils';

const HeaderCom = () => {
  return <div id="header-layout">{IS_GROUP ? <GroupHeader /> : IS_HUYA ? <HuyaHeader /> : <TmeHeader />}</div>;
};
export default HeaderCom;
