/*
 * @Author: princemwang
 * @Date: 2022-06-27 09:51:11
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-01 16:37:44
 */

import { Layout } from '@tencent/tea-component';
import { GuestRegister } from '@/components/guestRegister';
import { getSessionId } from '@/utils';
import { Navigate } from 'react-router-dom';
import config from '@/config';
import { getLiveStatus } from '@/api';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from '@/components/loading/Loading';
import './style.css';

const { Content, Body } = Layout;

export const RegisterHome = () => {
  const sessionId = getSessionId();
  const [state, setState] = useState<{ status: string; warmup?: boolean }>({ status: 'loading', warmup: false });
  const getStatus = useCallback(async () => {
    try {
      const { errorCode, data } = await getLiveStatus({ webcastId: config.webcastId, sessionId });
      if (errorCode === 0) {
        return setState({ status: data.status, warmup: data.warmup });
      }
      setState({ status: 'prior', warmup: false });
    } catch (error) {
      setState({ status: 'prior', warmup: false });
      console.log(error);
    }
  }, [sessionId]);
  useEffect(() => {
    getStatus();
  }, [getStatus]);
  return (
    <>
      {state.status === 'loading' ? (
        <Loading></Loading>
      ) : !sessionId || (state.status === 'prior' && !state.warmup) ? (
        <Body>
          <Content>
            <Content.Body full>
              <GuestRegister sessionId={sessionId}></GuestRegister>
            </Content.Body>
          </Content>
        </Body>
      ) : (
        <Navigate to={`/live/${config.webcastId}`} replace></Navigate>
      )}
    </>
  );
};
