/*
 * @Author: princemwang
 * @Date: 2022-06-29 18:21:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-07-07 16:35:58
 */
const config = {
  webcastId: window.location.pathname.split('/').pop(),
};
export default config;
