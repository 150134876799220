import HuyaPng from '@/assets/imgs/huyaLogo.png';
import './style.css';

const HuyaHeader = () => {
  return (
    <div className="huya-header">
      <div className="huya-header__content">
        <div className="logo-content">
          <img src={HuyaPng} alt="huya" />
        </div>
      </div>
    </div>
  );
};
export default HuyaHeader;
