/*
 * @Author: princemwang
 * @Date: 2022-07-03 21:14:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-23 15:05:50
 */
import { IS_GROUP, IS_HUYA } from '@/utils';
import GroupRegister from '@/components/guestRegister/groupRegister';
import TmeRegister from '@/components/guestRegister/tmeRegister';

export const GuestRegister = ({ sessionId }) => {
  return (
    <div className="reginter-body" style={{ width: '100%' }}>
      {IS_GROUP ? <GroupRegister sessionId={sessionId} /> : <TmeRegister sessionId={sessionId} isHuya={IS_HUYA} />}
    </div>
  );
};
