/*
 * @Author: princemwang
 * @Date: 2022-07-03 21:14:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-01 14:46:00
 */
import { RegisterForm } from '@/components/guestRegister/groupRegister/registerForm';
import { Biography } from '@/components/guestRegister/groupRegister/biography';
import { Row, Col } from '@tencent/tea-component';
import { useState, useEffect, useCallback } from 'react';
import { formatModayText } from '@/utils';
import { createInitFormText } from '@/globalState';
import RegisterModal from '@/components/guestRegister/registerModal';

const GroupRegister = ({ sessionId }) => {
  const [visible, setVisible] = useState(false);
  const [formText] = createInitFormText();
  const time = formText.startUtc;
  const text = formatModayText(time);
  useEffect(() => {
    if (sessionId) {
      setVisible(true);
    }
  }, [sessionId]);

  const openPage = useCallback(() => {
    window.location.href = 'https://www.tencent.com/en-us/investors.html';
  }, []);
  const showModay = useCallback(() => {
    setVisible(true);
  }, []);
  return (
    <>
      <Row className="row-div">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="content-col">
          <Biography></Biography>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="content-col">
          <RegisterForm showModay={showModay}></RegisterForm>
        </Col>
      </Row>
      <RegisterModal visible={visible} text={text} openPage={openPage}></RegisterModal>
    </>
  );
};
export default GroupRegister;
