/*
 * @Author: princemwang
 * @Date: 2022-07-15 15:16:37
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-07 15:24:44
 */
import livePng from '@/assets/imgs/live.png';
import { IS_GROUP } from '@/utils';
import './liveIcon.css';

export const LiveIcon = () => {
  return (
    <div className="liveing-icon">
      <img src={livePng} />
      <span>{IS_GROUP ? 'Live' : '直播中'}</span>
    </div>
  );
};
