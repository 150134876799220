/*
 * @Author: princemwang
 * @Date: 2023-02-01 14:30:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-01 14:22:08
 */
import { Modal, Button } from '@tencent/tea-component';
import { IS_GROUP, IS_HUYA } from '@/utils';

const RegisterModal = ({ visible, text, openPage }: { visible: boolean; text: string; openPage: () => void }) => {
  return (
    <Modal visible={visible} caption="" disableCloseIcon size="l">
      <Modal.Body
        style={{
          fontWeight: '700',
          fontSize: '14px',
          fontFamily: IS_HUYA ? 'Arial' : 'PingFang SC-medium',
          whiteSpace: 'pre-wrap',
        }}
      >
        {`You have registered. \nThe webcast will start at ${text}`}
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" onClick={openPage} className={IS_HUYA ? 'huya-btn' : 'tme-modal__btn'}>
          {IS_GROUP ? 'Back to Investors page' : IS_HUYA ? 'Back to Investor Relations Home' : 'Back to Investors Page'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default RegisterModal;
