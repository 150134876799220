/*
 * @Author: princemwang
 * @Date: 2022-07-03 20:51:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-07-03 21:43:39
 */
import { Loading } from '@/components/loading/Loading';
import NotFound from '@/pages/NotFound';

export const Authentication = ({ state, children }: { children: JSX.Element; state: string }) => {
  return <>{state === 'loading' ? <Loading></Loading> : state === 'found' ? children : <NotFound></NotFound>}</>;
};
