/*
 * @Author: princemwang
 * @Date: 2023-01-31 17:34:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-03 14:54:20
 */
import './style.css';
import TmePng from '@/assets/imgs/tme.png';
import { isMobile } from 'react-device-detect';

const TmeHeader = () => {
  return (
    <div className="tme-header">
      <div className="tme-header-content" style={{ justifyContent: 'space-between' }}>
        <img src={TmePng} alt="tme-logo" className={isMobile ? 'tmeLogo' : ''} />
        <div className={isMobile ? 'tme-header-right tme-mobile-text' : 'tme-header-right'}>
          <div>NYSE Stock Ticker&nbsp;: TME</div>
          <div>
            HKEX Stock Code&nbsp;&nbsp;<span style={{ marginLeft: '1.5px' }}>:</span> 1698
          </div>
        </div>
      </div>
    </div>
  );
};
export default TmeHeader;
