/*
 * @Author: princemwang
 * @Date: 2023-04-10 10:35:48
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-14 11:00:25
 */
import Back from '@/components/svgCom/Back';
import Fast from '@/components/svgCom/Fast';
import { isMobile } from 'react-device-detect';
import './common.css';
const DELY = 10;

const BackFast = ({
  state,
  audioRef,
  clickHandle,
}: {
  state: boolean;
  audioRef: React.MutableRefObject<HTMLAudioElement>;
  clickHandle?: (status: boolean) => void;
}) => {
  const backFastHandle = (status: boolean) => {
    if (!state || !audioRef.current) return;
    const audioDom = audioRef.current;
    const curentTime = parseInt(String(audioDom.currentTime), 10);
    const totalTime = parseInt(String(audioDom.duration), 10);
    if (status) {
      // 快进
      const addTime = curentTime + DELY;
      if (curentTime === totalTime) return;
      if (addTime > totalTime) {
        audioDom.currentTime = totalTime;
      } else if (addTime < totalTime) {
        audioDom.currentTime = addTime;
      }
    } else {
      //
      const reduceTime = curentTime - DELY;
      if (curentTime === 0) return;
      if (reduceTime < 0) {
        audioDom.currentTime = 0;
      } else {
        audioDom.currentTime = reduceTime;
      }
    }
    clickHandle?.(status);
  };
  return (
    <div className="back-fast__layout" style={{ marginLeft: isMobile ? '20px' : '30px' }}>
      <div
        className="back-fast__icon"
        style={{ cursor: state ? 'pointer' : 'not-allowed' }}
        onClick={() => {
          backFastHandle(false);
        }}
      >
        <Fast></Fast>
      </div>
      <div className="icon-text">10s</div>
      <div
        className="back-fast__icon"
        style={{ cursor: state ? 'pointer' : 'not-allowed' }}
        onClick={() => {
          backFastHandle(true);
        }}
      >
        <Back></Back>
      </div>
    </div>
  );
};
export default BackFast;
