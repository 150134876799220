/*
 * @Author: princemwang
 * @Date: 2022-07-06 14:23:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-18 15:20:57
 */
/* eslint-disable react/no-unknown-property */
import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import Hls from 'hls.js';
import livePng from '@/assets/imgs/live.png';
import { AudioVolume } from '@/components/liveAndPlayback/audioCom/AudioVolume';
import { reportInfo, getLiveStatus } from '@/api';
import config from '@/config';
import { getSessionId, isWeiXin, IS_IOS } from '@/utils';
import { isMobile } from 'react-device-detect';
import { MutedTooltip } from '@/components/liveAndPlayback/MutedTooltip';
import { createInitState } from '@/globalState';
import aegisUtils from '@/utils/aegisReport';
import { useGetUtcTime } from '@/hooks';
import './liveIng.css';
import { LiveIcon } from './LiveIcon';

export const LiveIng = ({ src }: { src?: string }) => {
  const audioRef = useRef(null);
  const volumeRef = useRef(null);
  const [bubbleVisible, setBubbleVisible] = useState(false);
  const onceState = useRef(false);
  const isPlay = useRef(false);
  const [isStartReport, setStartReport] = useState(false);
  const [mobileVisible, setMobileVisible] = useState(false);
  const [, setState] = createInitState();
  const weixin = isWeiXin();
  const hlsRef = useRef(null);
  const timeId = useRef(null);
  const isClickMuted = useRef(false);
  const curentSrc = useRef(src);
  const aegis = useMemo(() => {
    const { aegis } = aegisUtils();
    return aegis;
  }, []);
  const serverUtc = useGetUtcTime(isStartReport);
  const bufferEmpty = useRef(0);
  const audioPlay = useCallback(
    (cb?: () => void) => {
      const playPromise = audioRef.current?.play?.();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // 这里就已经开始播放了

            if (isClickMuted.current) return;
            if (isMobile || weixin) {
              volumeRef.current?.muteVolume();
              setMobileVisible(true);
            } else {
              volumeRef.current.applyAudio();
            }
          })
          .catch((error) => {
            if (isClickMuted.current) return;
            if (isMobile || weixin) {
              setMobileVisible(true);
            } else {
              setBubbleVisible(true);
            }
            volumeRef.current?.muteVolume();
            console.log(error, 'error');
          })
          .finally(() => {
            cb?.();
          });
      }
    },
    [weixin, isClickMuted],
  );
  const initHls = useCallback(
    (src) => {
      if (Hls.isSupported()) {
        hlsRef.current = new Hls(); // 实例化 Hls 对象
        hlsRef.current.loadSource(src); // 传入路径
        hlsRef.current.attachMedia(audioRef.current);
        hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
          // 加载成功
          setStartReport(true);
          if (weixin && !IS_IOS) {
            volumeRef.current?.muteVolume();
            setMobileVisible(true);
            return;
          }
          audioPlay();
        });
        hlsRef.current.on(Hls.Events.ERROR, (event, data) => {
          try {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  if (
                    data.details === Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT ||
                    data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR ||
                    data.details === Hls.ErrorDetails.MANIFEST_PARSING_ERROR
                  ) {
                    hlsRef.current.loadSource(src);
                  } else {
                    hlsRef.current.startLoad();
                  }
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
                    // 卡顿
                    console.log(Hls, '出现卡顿了');
                    if (aegis) {
                      aegis.infoAll({
                        method: 'stopped',
                        agent: window.navigator.userAgent,
                        currentTime: audioRef.current.currentTime,
                        bandWidth: hlsRef.current.bandwidthEstimate,
                      });
                    }
                    bufferEmpty.current = bufferEmpty.current + 1;
                  }
                  hlsRef.current.recoverMediaError();
                  console.log(event, data, '===media==');
                  break;
                default:
                  console.log(data, 'default');
                  hlsRef.current?.destroy?.();
                  break;
              }
            } else {
              audioRef.current.pause?.();
              hlsRef.current?.destroy?.();
              initHls(src);
            }
          } catch (error) {
            console.log(error, '捕获的Hls.Events.ERROR');
          }
        });
        // hlsRef.current.on(Hls.Events.LEVEL_SWITCHING, (_eventName, data) => {
        //   console.log(hlsRef.current.levels[data.level], '******');
        // });
      } else if (audioRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        setStartReport(true);
        audioRef.current.src = src;
        audioPlay(() => {
          if (timeId.current) {
            clearInterval(timeId.current);
            timeId.current = null;
          }
          timeId.current = setInterval(() => {
            if (!audioRef.current.currentTime) {
              audioRef.current.src = src;
              audioPlay();
            } else {
              clearInterval(timeId.current);
              timeId.current = null;
            }
          }, 1000);
        });
        audioRef.current.addEventListener('loadedmetadata', function () {
          console.log('不支持HLS-loadedmetadata');
        });
      }
    },
    [weixin, audioPlay, aegis],
  );
  useEffect(() => {
    if (!audioRef.current || !src) return;
    initHls(src);
    return () => {
      hlsRef.current?.destroy?.();
      if (timeId.current) {
        clearInterval(timeId.current);
      }
    };
  }, [src, initHls]);
  useEffect(() => {
    const sessionId = getSessionId();
    if (!isStartReport) return;
    const reportHandle = async () => {
      try {
        const curentTime = audioRef.current?.currentTime ?? 0;
        reportInfo({
          webcastId: config.webcastId,
          sessionId,
          playTime: curentTime,
          agent: window.navigator.userAgent,
          enterUtc: serverUtc,
          bufferEmpty: bufferEmpty.current,
        });
        bufferEmpty.current = 0;
      } catch (error) {
        console.log(error);
      }
    };
    const timeOut = setInterval(() => {
      reportHandle();
    }, 10000);
    return () => {
      clearInterval(timeOut);
    };
  }, [isStartReport, serverUtc, bufferEmpty]);
  const hiddleBubbleHandle = useCallback(() => {
    if (onceState.current) return;
    onceState.current = true;
    if (!audioRef.current) return;
    if (weixin || isMobile) {
      setMobileVisible(false);
    } else {
      setBubbleVisible(false);
    }
    isClickMuted.current = true;
    audioRef.current.play?.();
    volumeRef.current.applyAudio();
  }, [audioRef, weixin]);
  const visibleChange = useCallback(() => {
    isClickMuted.current = true;
    audioRef.current.play?.();
    volumeRef.current.applyAudio();
    setMobileVisible(false);
  }, [audioRef]);
  useEffect(() => {
    let timeId = null;
    const sessionId = getSessionId();
    const refreshStatus = async () => {
      try {
        const { data, errorCode } = await getLiveStatus({ webcastId: config.webcastId, sessionId });
        if (errorCode !== 0) {
          return false;
        }
        const { stream } = data;
        if (stream) {
          const url = curentSrc.current;
          if (url !== stream) {
            audioRef.current.pause?.();
            console.log('地址不一样了');
            if (hlsRef.current) {
              hlsRef.current?.destroy?.();
            }
            initHls(stream);
            curentSrc.current = stream;
          }
        }
        if (data.status === 'post') {
          setState(data);
        }
      } catch (error) {
        //
      }
    };
    timeId = setInterval(() => {
      refreshStatus();
    }, 10000);
    return () => {
      clearInterval(timeId);
    };
  }, [setState, initHls]);
  return (
    <div className="liveing">
      <LiveIcon></LiveIcon>
      <div className="liveing-volume">
        <audio ref={audioRef} preload="auto" cross-origin-isolated="true" autoPlay={true}>
          Your browser does not support the audio element.
        </audio>
        <AudioVolume
          audioRef={audioRef}
          ref={volumeRef}
          bubbleVisible={bubbleVisible}
          hideBubble={hiddleBubbleHandle}
        ></AudioVolume>
      </div>
      <MutedTooltip visible={mobileVisible} visibleChange={visibleChange}></MutedTooltip>
    </div>
  );
};
