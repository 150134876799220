/*
 * @Author: princemwang
 * @Date: 2023-04-06 15:28:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-10 15:05:21
 */
const BackIcon = ({ isWhite = true }: { isWhite?: boolean }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.33337 0L16 8L5.33337 16V0Z" fill={isWhite ? 'black' : 'white'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00006 3.00005L0 0V16L4.00006 13V3.00005Z"
        fill={isWhite ? 'black' : 'white'}
      />
    </svg>
  );
};
export default BackIcon;
