/*
 * @Author: princemwang
 * @Date: 2023-03-24 10:06:10
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-14 11:01:32
 */
/* eslint-disable */
import { List, Bubble } from "@tencent/tea-component";
import { useState, useRef, useLayoutEffect } from "react";
import { isMobile, isSafari } from "react-device-detect";
import { throttle } from "@/utils";
import "./style.css";

export interface MarkList {
  time: string | number;
  text: string;
  id: string;
  isEdit?: boolean;
  isAdd?: boolean;
  [key: string]: any;
}
interface Props {
  audioRef: React.MutableRefObject<any>;
  setAudioTime: (val: number) => any;
  list: any[];
  isMobileIosStatus: React.MutableRefObject<boolean>;
}

// eslint-disable-next-line react/display-name
export const Menus = ({
  list,
  audioDom,
  isMobileIosStatus,
}: {
  list: MarkList[];
  audioDom: HTMLAudioElement;
  isMobileIosStatus: React.MutableRefObject<boolean>;
}) => (
  <List type="option" style={{ padding: "6px 0px" }}>
    {list.map((ele) => (
      <List.Item
        style={{ padding: "6px 12px" }}
        onClick={() => {
          if (audioDom) {
            if (isMobile && isSafari) {
              const oldVolume = audioDom.volume;
              if (audioDom.paused) {
                audioDom.play?.();
                isMobileIosStatus.current = true;
                audioDom.volume = 0;
              } else {
                isMobileIosStatus.current = false;
              }
              const timeOut = setTimeout(() => {
                if (isMobileIosStatus.current) {
                  audioDom.pause();
                  audioDom.volume = oldVolume;
                }
                audioDom.currentTime = Number(ele.time);
                clearInterval(timeOut);
              }, 500);
            } else {
              audioDom.currentTime = Number(ele.time);
            }
          }
        }}
        key={ele.id}
      >
        <span
          style={{
            marginRight: "10px",
            display: "inline-block",
            fontSize: isMobile ? "14px" : "16px",
          }}
        >
          {ele.timeText}
        </span>
        <span style={{ fontSize: isMobile ? "14px" : "16px" }}>{ele.text}</span>
      </List.Item>
    ))}
  </List>
);
const defaultPostion = {
  right: "9999px",
  top: "-9999px",
};
export const Contents = ({
  audioRef,
  setAudioTime,
  list,
  isMobileIosStatus,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const btnRef = useRef(null);
  const menuRef = useRef(null);
  const [postion, setPostion] = useState(defaultPostion);
  const [resizeState, setResizeState] = useState({ state: false });
  useLayoutEffect(() => {
    const getCenter = (width: number, left: number) => {
      return left + Math.floor(width / 2);
    };
    const btnTarget = btnRef.current as HTMLDivElement;
    const clickHandle = (ev: MouseEvent) => {
      if (ev.target !== btnTarget && visible) {
        setVisible(false);
      }
    };
    window.addEventListener("click", clickHandle);

    if (visible) {
      const menuTarget = menuRef.current as HTMLDivElement;
      if (menuTarget && btnTarget) {
        const domReact = menuTarget.getBoundingClientRect();
        const btnReact = btnTarget.getBoundingClientRect();
        const domLeft =
          getCenter(Math.floor(btnReact.width), Math.floor(btnReact.x)) -
          Math.floor(domReact.width / 2);
        setPostion({
          right: `${domLeft}px`,
          top: `${btnReact.top - 10 - domReact.height}px`,
        });
      }
    } else {
      setPostion(defaultPostion);
    }
    const resizeHandle = throttle(() => {
      setResizeState({ state: true });
    }, 500);
    window.addEventListener("resize", resizeHandle);
    return () => {
      window.removeEventListener("click", clickHandle);
      window.removeEventListener("resize", resizeHandle);
    };
  }, [btnRef, menuRef, visible, resizeState]);
  const markList = list.map((ele) => {
    const item = {
      ...ele,
      timeText: setAudioTime(Number(ele.time)),
    };
    return item;
  });
  const btnClick = () => {
    setVisible((val) => !val);
  };
  return (
    <Bubble
      trigger="click"
      overlayClassName="content-tootip"
      content={
        <Menus
          list={markList}
          audioDom={audioRef.current}
          isMobileIosStatus={isMobileIosStatus}
        ></Menus>
      }
    >
      <div
        className="mark-content"
        style={{ marginRight: isMobile ? "20px" : "30px" }}
      >
        <span
          onClick={btnClick}
          ref={btnRef}
          className="speed-btn-text"
          style={{
            color: visible ? "#006EFF" : "#333333",
            fontSize: isMobile ? "12px" : "16px",
          }}
        >
          Contents
        </span>
      </div>
    </Bubble>
  );
};
