/*
 * @Author: princemwang
 * @Date: 2023-01-31 18:08:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-23 15:49:52
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import './style.css';
import { Form, Input, Button, Row, Col, Select } from '@tencent/tea-component';
import { useForm, useField } from 'react-final-form-hooks';
import { useSubmitForm } from '@/hooks';
import { isMobile } from 'react-device-detect';
import config from '@/config';
import { useMemo, useState, useEffect, useCallback } from 'react';
import RegisterModal from '@/components/guestRegister/registerModal';
import { tmeTimeString, FORM_OPTIONS, huyaTimeString } from '@/utils';
import { createInitFormText } from '@/globalState';

const TmeRegister = ({ sessionId, isHuya = true }: { sessionId: string; isHuya?: boolean }) => {
  const [formText] = createInitFormText();
  const time = formText.startUtc;
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (sessionId) {
      setVisible(true);
    }
  }, [sessionId]);

  const openPage = useCallback(() => {
    const href = isHuya ? 'https://ir.huya.com/' : 'https://ir.tencentmusic.com/';
    window.location.href = href;
  }, [isHuya]);
  const localVal = localStorage.getItem(config.webcastId);
  const initValues = localVal
    ? JSON.parse(localVal)
    : { firstName: '', lastName: '', company: '', email: '', occupation: '' };
  function getStatus(meta, validating) {
    if (meta.active && validating) {
      return 'validating';
    }
    if (!meta.touched) {
      return null;
    }
    return meta.error ? 'error' : 'success';
  }
  const onSubmit = useSubmitForm(() => {
    setVisible(true);
  }) as any;
  const { form, handleSubmit, submitting, validating } = useForm({
    onSubmit,
    /**
     * 默认为 shallowEqual
     * 如果初始值有多层，会导致重渲染，也可以使用 `useEffect` 设置初始值：
     * useEffect(() => form.initialize({ }), []);
     */
    initialValuesEqual: () => true,
    initialValues: initValues,
    validate: ({ firstName, lastName, company, email, occupation }: any) => {
      const isEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email);
      return {
        firstName:
          firstName?.trim?.()?.length <= 0
            ? 'please enter your first name'
            : firstName?.trim?.()?.length > 50
            ? 'up to 50 characters'
            : undefined,
        lastName:
          lastName?.trim?.()?.length <= 0
            ? 'please enter your last name'
            : lastName?.trim?.()?.length > 50
            ? 'up to 50 characters'
            : undefined,
        company:
          company?.trim?.()?.length <= 0
            ? 'please enter your company'
            : company?.trim?.()?.length > 50
            ? 'up to 50 characters'
            : undefined,
        email: isEmail ? undefined : 'please enter your vaild email',
        occupation: occupation ? undefined : 'please select your occupation',
      };
    },
  });
  const firstName = useField('firstName', form);
  const lastName = useField('lastName', form);
  const company = useField('company', form);
  const email = useField('email', form);
  const occupation = useField('occupation', form);
  const disable = useMemo(() => {
    const isEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email.input.value);
    const isDisable =
      firstName.input.value &&
      firstName.input.value?.length <= 50 &&
      lastName.input.value &&
      lastName.input.value?.length <= 50 &&
      company.input.value &&
      company.input.value?.length <= 50 &&
      email.input.value &&
      email.input.value?.length <= 50 &&
      isEmail &&
      occupation.input.value;
    return !isDisable;
  }, [firstName, lastName, company, email, occupation]);
  const formTitle = isHuya
    ? 'To view this webcast, please provide the requested information below.'
    : 'To view this presentation, please provide the requested information below.';
  return (
    <div className={isHuya ? 'tme-register huya-register' : 'tme-register'}>
      <div className="tme-title">
        <div className={isMobile ? 'tme-title-top tme-title-topMobile' : 'tme-title-top'}>{formText.title}</div>
        <div className="tme-title-bottom">{isHuya ? huyaTimeString(time) : tmeTimeString(time)}</div>
      </div>
      <div className="tme-form">
        <form onSubmit={handleSubmit as any}>
          <Form.Title className="tme-form-title">{formTitle}</Form.Title>
          <Form layout="vertical" style={{ width: '100%' }}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={<div style={{ paddingLeft: '10px' }}>First name</div>}
                  required
                  status={getStatus(firstName.meta, validating)}
                  message={getStatus(firstName.meta, validating) === 'error' && firstName.meta.error}
                >
                  <Input placeholder="Your first name" {...firstName.input} autoComplete="off" size="full" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={<div style={{ paddingLeft: '10px' }}>Last name</div>}
                  required
                  status={getStatus(lastName.meta, validating)}
                  message={getStatus(lastName.meta, validating) === 'error' && lastName.meta.error}
                >
                  <Input placeholder="Your last name" {...lastName.input} autoComplete="off" size="full" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<div style={{ paddingLeft: '10px' }}>Email</div>}
                  required
                  status={getStatus(email.meta, validating)}
                  message={getStatus(email.meta, validating) === 'error' && email.meta.error}
                >
                  <Input placeholder="Your email address" {...email.input} autoComplete="off" size="full" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<div style={{ paddingLeft: '10px' }}>Company</div>}
                  required
                  status={getStatus(company.meta, validating)}
                  message={getStatus(company.meta, validating) === 'error' && company.meta.error}
                >
                  <Input placeholder="Your company" {...company.input} autoComplete="off" size="full" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<div style={{ paddingLeft: '10px' }}>Occupation</div>}
                  required
                  status={getStatus(occupation.meta, validating)}
                  message={getStatus(occupation.meta, validating) === 'error' && occupation.meta.error}
                >
                  <Select
                    size="full"
                    appearance="button"
                    matchButtonWidth
                    {...occupation.input}
                    options={FORM_OPTIONS}
                    placeholder="Your occupation"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </form>
        {!isHuya && (
          <Button
            type="primary"
            className={isMobile ? 'moble-btn tme-btn' : 'tme-btn'}
            loading={submitting}
            disabled={disable}
            onClick={() => {
              form.submit();
            }}
          >
            Submit
          </Button>
        )}
        {isHuya && (
          <Button
            type="primary"
            className={isMobile ? 'moble-btn tme-btn huya-btn' : 'tme-btn huya-btn'}
            loading={submitting}
            disabled={disable}
            onClick={() => {
              form.submit();
            }}
          >
            Submit
          </Button>
        )}
      </div>
      <RegisterModal
        visible={visible}
        text={isHuya ? huyaTimeString(time) : tmeTimeString(time)}
        openPage={openPage}
      ></RegisterModal>
    </div>
  );
};
export default TmeRegister;
