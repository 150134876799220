/*
 * @Author: princemwang
 * @Date: 2022-06-27 16:15:58
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-18 15:25:21
 */
/* eslint-disable react/no-unknown-property */
import { AudioVolume } from '@/components/liveAndPlayback/audioCom/AudioVolume';
import { useRef, useLayoutEffect, useState, useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { MutedTooltip } from '@/components/liveAndPlayback/MutedTooltip';
import { isWeiXin, getSessionId, IS_IOS } from '@/utils';
import { getLiveStatus, reportInfo } from '@/api';
import config from '@/config';
import { useGetUtcTime } from '@/hooks';
import { createInitState, createInitRefreshPpt } from '@/globalState';
import { LiveIcon } from '@/components/liveAndPlayback/liveing/LiveIcon';
import './style.css';

export const NoStart = ({ music = '', warmup }: { music?: string; warmup: boolean }) => {
  const volumeRef = useRef(null);
  const audioRef = useRef(null);
  const onceState = useRef(false);
  const [, setState] = createInitState();
  const serverUtc = useGetUtcTime(warmup);
  const [, setRefPpt] = createInitRefreshPpt();
  const [bubbleVisible, setBubbleVisible] = useState(false);
  const [mobileVisible, setMobileVisible] = useState(false);
  const weixin = isWeiXin();
  const [enterUtc, setEnterUtc] = useState('');

  useLayoutEffect(() => {
    if (warmup && audioRef.current) {
      if (weixin && !IS_IOS) {
        volumeRef.current?.muteVolume();
        setMobileVisible(true);
        return;
      }
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            volumeRef.current.applyAudio();
          })
          .catch((error) => {
            console.log(error);
            volumeRef.current?.muteVolume();
            if (isMobile || weixin) {
              setMobileVisible(true);
            } else {
              setBubbleVisible(true);
            }
            // 无法自动播放
            // 显示暂停的UI
          });
      }
    }
  }, [warmup, audioRef, weixin]);
  useEffect(() => {
    let timeId = null;
    if (!warmup) return;
    const sessionId = getSessionId();
    const refreshStatus = async () => {
      try {
        const { data, errorCode } = await getLiveStatus({ webcastId: config.webcastId, sessionId });
        if (errorCode !== 0) {
          return false;
        }
        reportInfo({
          webcastId: config.webcastId,
          sessionId,
          agent: window.navigator.userAgent,
          enterUtc: serverUtc,
        });
        if (data.status === 'live') {
          setRefPpt(true);
          setState(data);
        }
      } catch (error) {
        //
      }
    };
    timeId = setInterval(() => {
      refreshStatus();
    }, 10000);
    return () => {
      clearInterval(timeId);
    };
  }, [warmup, setState, setRefPpt, serverUtc]);
  const hiddleBubbleHandle = useCallback(() => {
    if (onceState.current) return;
    onceState.current = true;
    if (!audioRef.current) return;
    audioRef.current.play();
    volumeRef.current.applyAudio();
    if (weixin || isMobile) {
      setMobileVisible(false);
    } else {
      setBubbleVisible(false);
    }
  }, [audioRef, weixin]);
  const visibleChange = useCallback(() => {
    setMobileVisible(false);
    audioRef.current.play();
    volumeRef.current.applyAudio();
  }, [audioRef]);
  return (
    <div className="nostart">
      <audio ref={audioRef} preload="auto" cross-origin-isolated="true" src={music} autoPlay={warmup} loop={true}>
        Your browser does not support the audio element.
      </audio>
      {warmup && <LiveIcon></LiveIcon>}
      {warmup && (
        <AudioVolume
          audioRef={audioRef}
          ref={volumeRef}
          bubbleVisible={bubbleVisible}
          hideBubble={hiddleBubbleHandle}
        ></AudioVolume>
      )}
      <MutedTooltip visible={mobileVisible} visibleChange={visibleChange}></MutedTooltip>
    </div>
  );
};
