/*
 * @Author: princemwang
 * @Date: 2022-06-22 14:21:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-18 15:19:30
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { useCallback, useState, useMemo } from 'react';
import { Form, Input, Text, Checkbox, Button, message, Select } from '@tencent/tea-component';
import { useForm, useField } from 'react-final-form-hooks';
import { setGuestInfo, getLiveStatus } from '@/api/index';
import { Guest } from '@/request/reqType';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import { isMobile } from 'react-device-detect';
import './style.css';
import config from '@/config';
import { FORM_OPTIONS, TITLE_OPTIONS } from '@/utils';
import aegisUtils from '@/utils/aegisReport';

export const RegisterForm = ({ showModay }: { showModay: () => void }) => {
  const localVal = localStorage.getItem(config.webcastId);
  const [checkValue, setCheckValue] = useState(Boolean(localVal));
  const navigate = useNavigate();
  const { aegis } = aegisUtils();
  const onSubmit = useCallback(
    async (values) => {
      try {
        const datas: Guest = {
          ...values,
          webcastId: config.webcastId,
        };
        const res = await setGuestInfo(datas);
        aegis.reportEvent({
          name: 'register success', // 必填
          ext1: 'submit',
        });
        const { sessionId } = res.data;
        if (!sessionId) {
          return message.error({ content: 'Network error, please try again later.' });
        }
        Cookie.set(config.webcastId, sessionId, { expires: 90 });
        localStorage.setItem(config.webcastId, JSON.stringify(values));
        const { errorCode, data } = await getLiveStatus({ webcastId: config.webcastId, sessionId });
        if (errorCode !== 0) return false;
        if (data.status === 'prior' && !data.warmup) {
          showModay();
        } else {
          navigate(`/live/${config.webcastId}`, {
            replace: true,
          });
        }
      } catch (error) {
        message.error({ content: 'Network error, please try again later.' });
        console.log(error);
        aegis.reportEvent({
          name: 'register error', // 必填
          ext1: 'submit',
          ext2: `${error}`,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, showModay],
  );

  const initValues = localVal
    ? JSON.parse(localVal)
    : { title: '', firstName: '', lastName: '', company: '', email: '', occupation: '' };
  const { form, handleSubmit, submitting, validating } = useForm({
    onSubmit,
    /**
     * 默认为 shallowEqual
     * 如果初始值有多层，会导致重渲染，也可以使用 `useEffect` 设置初始值：
     * useEffect(() => form.initialize({ }), []);
     */
    initialValuesEqual: () => true,
    initialValues: initValues,
    validate: ({ firstName, lastName, company, email, occupation, title }) => {
      const isEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email);
      return {
        title: title?.length > 50 ? 'up to 50 characters' : undefined,
        firstName:
          firstName?.trim?.()?.length <= 0
            ? 'please enter your first name'
            : firstName?.trim?.()?.length > 50
            ? 'up to 50 characters'
            : undefined,
        lastName:
          lastName?.trim?.()?.length <= 0
            ? 'please enter your last name'
            : lastName?.trim?.()?.length > 50
            ? 'up to 50 characters'
            : undefined,
        company:
          company?.trim?.()?.length <= 0
            ? 'please enter your company'
            : company?.trim?.()?.length > 50
            ? 'up to 50 characters'
            : undefined,
        email: isEmail ? undefined : 'please enter your vaild email',
        occupation: occupation?.trim?.()?.length > 0 ? undefined : 'please enter your occupation',
      };
    },
  });

  const title = useField('title', form);
  const firstName = useField('firstName', form);
  const lastName = useField('lastName', form);
  const company = useField('company', form);
  const email = useField('email', form);
  const occupation = useField('occupation', form);

  const checkBoxHandle = useCallback((val) => {
    setCheckValue(val);
  }, []);
  const disable = useMemo(() => {
    const isEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email.input.value);
    const isDisable =
      title.input.value?.length <= 50 &&
      firstName.input.value &&
      firstName.input.value?.length <= 50 &&
      lastName.input.value &&
      lastName.input.value?.length <= 50 &&
      company.input.value &&
      company.input.value?.length <= 50 &&
      email.input.value &&
      email.input.value?.length <= 50 &&
      occupation.input.value &&
      checkValue &&
      isEmail;
    return !isDisable;
  }, [firstName, lastName, company, email, occupation, checkValue, title]);
  function getStatus(meta, validating) {
    if (meta.active && validating) {
      return 'validating';
    }
    if (!meta.touched) {
      return null;
    }
    return meta.error ? 'error' : 'success';
  }
  return (
    <div className="form-content" style={{ paddingTop: isMobile ? '30px' : '0px' }}>
      <form onSubmit={handleSubmit as any}>
        <Form.Title className="register-title">Please register to access this webcast</Form.Title>
        <Form>
          <Form.Item
            label="Title"
            status={getStatus(title.meta, validating)}
            message={getStatus(title.meta, validating) === 'error' && title.meta.error}
          >
            <Select
              size="full"
              appearance="button"
              matchButtonWidth
              {...title.input}
              options={TITLE_OPTIONS}
              placeholder="Title"
              clearable
            />
          </Form.Item>
          <Form.Item
            label="First name"
            required
            status={getStatus(firstName.meta, validating)}
            message={getStatus(firstName.meta, validating) === 'error' && firstName.meta.error}
          >
            <Input placeholder="First name" {...firstName.input} autoComplete="off" size="full" />
          </Form.Item>
          <Form.Item
            label="Last name"
            required
            status={getStatus(lastName.meta, validating)}
            message={getStatus(lastName.meta, validating) === 'error' && lastName.meta.error}
          >
            <Input placeholder="Last name" {...lastName.input} autoComplete="off" size="full" />
          </Form.Item>
          <Form.Item
            label="Company"
            required
            status={getStatus(company.meta, validating)}
            message={getStatus(company.meta, validating) === 'error' && company.meta.error}
          >
            <Input placeholder="Company" {...company.input} autoComplete="off" size="full" />
          </Form.Item>
          <Form.Item
            label="E-mail"
            required
            status={getStatus(email.meta, validating)}
            message={getStatus(email.meta, validating) === 'error' && email.meta.error}
          >
            <Input placeholder="E-mail" {...email.input} autoComplete="off" size="full" />
          </Form.Item>
          <Form.Item
            label="Occupation"
            required
            status={getStatus(occupation.meta, validating)}
            message={getStatus(occupation.meta, validating) === 'error' && occupation.meta.error}
          >
            <Select
              size="full"
              appearance="button"
              matchButtonWidth
              {...occupation.input}
              options={FORM_OPTIONS}
              placeholder="Occupation"
              clearable
            />
          </Form.Item>
        </Form>
      </form>
      <div className="form-safe">
        <Text theme="strong">Safe Harbor Statement --</Text>
      </div>
      <div style={{ textAlign: 'justify' }}>
        <Text theme="label" className="form-introduce">
          This webcast may contain "forward-looking statements" (as defined in Section 27A(I)(1) of the Securities Act
          of 1933, as amended) including statements regarding, among other things, the Company&apos;s business strategy
          and growth strategy. Expressions which identify forward-looking statements speak only as of the date the
          statement is made. These forward-looking statements are based largely on this Company&apos;s expectations and
          are subject to a number of risks and uncertainties, some of which cannot be predicted or quantified and are
          beyond their control. Future developments and actual results could differ materially from those set forth in,
          contemplated by, or underlying the forward-looking statements. In light of these risks and uncertainties,
          there can be no assurance that the forward-looking information will prove to be accurate. This webcast does
          not constitute an offer to purchase any securities, nor a solicitation of a proxy, consent, authorization or
          agent designation with respect to a meeting of Company&apos;s stockholders.
        </Text>
      </div>
      <div className="form-checkbox">
        <Checkbox name="agree" value={checkValue} onChange={checkBoxHandle}>
          I agree to and accept the above Safe Harbor Statement
        </Checkbox>
      </div>
      <div>
        <Text theme="primary">Submitting this form indicates your acceptance of the Privacy Statement.</Text>
      </div>
      <div className="form-btn">
        <Button
          type="primary"
          className={isMobile ? 'moble-btn' : ''}
          loading={submitting}
          disabled={disable}
          onClick={() => {
            form.submit();
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
