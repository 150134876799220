/*
 * @Author: princemwang
 * @Date: 2022-06-22 14:59:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-05-16 13:09:22
 */
import { Layout } from '@tencent/tea-component';
import HeaderCom from '@/components/header/Header';
import { Outlet } from 'react-router-dom';
import { createIosFullScreen } from '@/globalState';
import footerLogo from '@/assets/imgs/footerLogo.png';
import { isMobile } from 'react-device-detect';
import { IS_GROUP } from '@/utils';

const { Header, Footer } = Layout;

const Home = () => {
  const [state] = createIosFullScreen();
  return (
    <>
      <Layout>
        <Header style={{ display: state ? 'none' : 'block' }}>
          <HeaderCom></HeaderCom>
        </Header>
        <Outlet></Outlet>
        {isMobile && IS_GROUP && (
          <Footer className="webcast-footer">
            <div className="layout-footer">
              <div className="layout-footer__logo">
                <img src={footerLogo} alt="tencent cloud" />
              </div>
              <div className="layout-footer__text">Powered by Tencent Cloud Media Services:TRTC_helper@tencent.com</div>
            </div>
          </Footer>
        )}
      </Layout>
      <div className={state ? 'swiper-parent' : ''}></div>
    </>
  );
};
export default Home;
