import React, { useRef, useLayoutEffect, useState, useMemo } from 'react';
/*
 * @Author: princemwang
 * @Date: 2023-02-09 10:45:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-23 18:06:20
 */
const AudioMarkItem = ({
  style,
  isAdd,
  text,
  timeText,
  hoverHandle,
  processWidth,
}: {
  style: React.CSSProperties;
  isAdd: boolean;
  text: string;
  timeText: string;
  hoverHandle: (type: 'enter' | 'leave') => void;
  processWidth: number;
}) => {
  const maskRef = useRef(null);
  const [maskLeft, setMaskLeft] = useState(0);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    if (!maskRef.current) return;
    const { width, height } = maskRef.current.getBoundingClientRect();
    setHeight(height);
    if (width) {
      setMaskLeft(Math.floor(width / 2));
    }
  }, [maskRef]);
  const styLeft = useMemo(() => {
    const left =
      Number(style.left) - maskLeft < 0
        ? 0
        : Math.floor(Number(style.left)) >= Math.floor(processWidth) - 328
        ? Math.floor(processWidth - 328)
        : Number(style.left) - maskLeft;
    return left;
  }, [style, maskLeft, processWidth]);
  return (
    <>
      <div
        className={isAdd ? 'progress-mark' : 'progress-mark mark-init'}
        style={{ left: `${style.left}px`, top: '0px', height: '4px' }}
        onMouseEnter={() => {
          hoverHandle('enter');
        }}
        onMouseLeave={() => {
          hoverHandle('leave');
        }}
      ></div>
      <div
        className="audio-trapezoidal-mask audio-init-mask"
        style={{ left: `${styLeft}px`, top: `-${height + 20}px` }}
        ref={maskRef}
      >
        <div className="audio-trapezoidal-maskText">{timeText}</div>
        <div className="audio-trapezoidal-maskText">{text}</div>
      </div>
    </>
  );
};
export default AudioMarkItem;
