/*
 * @Author: princemwang
 * @Date: 2022-06-22 15:30:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-05-17 15:35:42
 */
import { Text, Row, Col } from '@tencent/tea-component';
import Huateng from '@/assets/imgs/Huateng.png';
import LAU from '@/assets/imgs/Martin Lau.png';
import LO from '@/assets/imgs/LO.png';
import Mitchell from '@/assets/imgs/Mitchell.png';
import { createInitFormText } from '@/globalState';
import { formatDateText } from '@/utils';
import './style.css';

export const Biography = () => {
  const [formText] = createInitFormText();
  const time = formText.startUtc;
  const timeText = formatDateText(time);
  return (
    <div className="biography">
      <div className="biography-content">
        <Text theme="strong" className="live-h3">
          {formText.title}
        </Text>
      </div>
      <div className="biography-h2">
        <Text theme="strong">{timeText}</Text>
      </div>
      <div className="biography-img" style={{ height: '100%' }}>
        <Row gap={20}>
          <Col span={12}>
            <img src={Huateng} />
          </Col>
          <Col span={12}>
            <img src={LAU} />
          </Col>
          <Col span={12}>
            <img src={Mitchell} />
          </Col>
          <Col span={12}>
            <img src={LO} />
          </Col>
        </Row>
      </div>
    </div>
  );
};
