/*
 * @Author: princemwang
 * @Date: 2022-06-22 09:55:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-18 15:24:09
 */
import axios, { AxiosRequestConfig } from 'axios';
import { castApi } from '@/utils/castApiname';
import { ReqTypeKey } from './reqType';
import { ResType } from './resTypes';
import aegisUtils from '@/utils/aegisReport';

type ErrorType = {
  msg: string;
};
let baseUrl = window.location.origin;
const testHostnames = ['test2.rtc.qq.com', 'localhost', '127.0.0.1'];
if (testHostnames.includes(window.location.hostname)) {
  baseUrl = 'https://test3.rtc.qq.com';
} else {
  baseUrl = 'https://api.webcast.tencent.com';
}
const axioRequest = axios.create({
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
  timeout: 60000,
});
// 添加响应拦截器
axioRequest.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const { url, data } = response.config;
    const [, patnName] = url.split('/v1/');
    if (patnName) {
      const params = JSON.parse(data);
      const { aegis } = aegisUtils();
      const urlKey = patnName.split('/')[0];
      const methodName = castApi[urlKey] || '';
      const result = response.data;
      aegis.infoAll({ method: methodName, params, result, agent: window.navigator.userAgent });
    }

    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    const { url, data } = error.config;
    const params = JSON.parse(data);
    const [, patnName] = url.split('/v1/');
    if (patnName) {
      const { aegis } = aegisUtils();
      const urlKey = patnName.split('/')[0];
      const methodName = castApi[urlKey] || '';
      const result = error.response?.data;
      aegis.report({ method: methodName, params, result, agent: window.navigator.userAgent });
    }
    return Promise.reject(error);
  },
);
export const request = async <K extends ReqTypeKey>(req: AxiosRequestConfig): Promise<ResType[K]> => {
  const { url, params, data, method } = req;
  let result: { data: ResType[K] };
  try {
    result = await axioRequest.request({
      method,
      url: baseUrl + url,
      params,
      data,
    });
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
