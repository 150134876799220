/* eslint-disable */
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Pagination } from 'swiper';
import { isIOS, isMobile } from 'react-device-detect';
import { getDocs } from '@/api';
import { getSessionId, throttle, IS_IOS, isIphone15 } from '@/utils';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import config from '@/config';
import { createInitRefreshPpt, createIosFullScreen } from '@/globalState';
import { FullScreenSvg } from '@/components/svgCom/FullScreen';
import { useIsHorizontalScreen, useIsFullScreen } from '@/hooks';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.css';
import aegisUtils from '@/utils/aegisReport';

export const PriviewPpt = ({ status, warmup }: { status?: string; warmup: boolean | undefined }) => {
  const imgRef = useRef(null);
  const { aegis } = aegisUtils();
  const isLeftRef = useRef(0);
  const [refshPpt] = createInitRefreshPpt();
  const [parentWidth, setParentWidth] = useState('auto');
  const sessionId = getSessionId();
  const [screenState, setScreenState] = useState(false);
  const [, setIosFullScreen] = createIosFullScreen();
  const statusList = ['prior', 'post'];
  const [imgs, setImgList] = useState([]);
  const screen = useFullScreenHandle();
  const [totalPages, setTotalPages] = useState(0);
  const isHorizontalScreen = useIsHorizontalScreen();
  const isFullScreen = useIsFullScreen();
  const imgListRef = useRef([]);
  const getImgs = useCallback(async () => {
    try {
      const { data } = await getDocs({ webcastId: config.webcastId, sessionId });
      const imgList = data.slides?.map((ele) => ele.url);
      setTotalPages(imgList.length);
      const partList = imgList.splice(0, 3);
      setImgList(partList);
      imgListRef.current = imgList;
      const eleImg = new Image();
      const url = imgList[0];
      eleImg.onload = function () {
        const imgDom = document.getElementById('slide-0');
        if (!imgDom) return;
        setTimeout(() => {
          const imgDom = document.getElementById('slide-0');
          const width = imgDom.offsetWidth;
          if (!isMobile) {
            setParentWidth(`${width}px`);
          }
        });
      };
      eleImg.src = url;
    } catch (error) {
      //
    }
  }, [sessionId]);
  useEffect(() => {
    getImgs();
  }, [getImgs]);
  useEffect(() => {
    if (refshPpt) {
      getImgs();
    }
  }, [refshPpt, getImgs]);
  const imgList = useMemo(() => {
    if (statusList.includes(status) && imgs.length > 0 && !warmup) {
      return [imgs[0]];
    }
    if (imgs.length > 0) {
      return imgs;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgs, status, warmup]);
  useEffect(() => {
    const resizeHandle = throttle(() => {
      const imgDom = document.getElementById('slide-0');
      if (!imgDom) return;
      const width = imgDom.offsetWidth;
      setParentWidth(`${width}px`);
    }, 0);
    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
      setIosFullScreen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isFullScreen) {
      setScreenState(false);
      const imgDom = document.getElementById('slide-0');
      if (!imgDom) return;
      const width = imgDom.offsetWidth;
      setParentWidth(`${width}px`);
    }
  }, [isFullScreen]);
  useEffect(() => {
    if (!isMobile) return;
    if (isHorizontalScreen && screenState) {
      setParentWidth('100vw');
    } else if (isHorizontalScreen && !screenState) {
      const imgDom = document.getElementById('slide-0');
      if (!imgDom) return;
      const width = imgDom.offsetWidth;
      setParentWidth(`${width}px`);
    } else {
      setParentWidth(`${window.screen.availWidth}px`);
    }
  }, [screenState, isHorizontalScreen]);
  const imgMaxHeight = useMemo(() => {
    let height = '';
    if (isMobile) {
      if (isHorizontalScreen) {
        if (screenState) {
          if (isIOS && isIphone15) {
            height = 'calc(var(--vh) * 100 - 300px)';
          } else {
            height = 'calc(var(--vh) * 100)';
          }
        } else {
          height = 'calc(var(--vh) * 100 - 220px)';
        }
      } else {
        height = 'calc(100vh - 200px)';
      }
    } else {
      if (screenState) {
        height = '100%';
      } else {
        height = 'calc(100vh - 200px)';
      }
    }
    return height;
  }, [isHorizontalScreen, screenState]);
  useEffect(() => {
    const footerDom = document.getElementsByClassName('webcast-footer') as any;
    if (!footerDom) return;
    const footerDomList = [...footerDom];
    if (isMobile && isHorizontalScreen && screenState) {
      // 移动端横屏并且全屏
      footerDomList.forEach((item: HTMLDivElement) => {
        item.style.display = 'none';
      });
    } else {
      footerDomList.forEach((item: HTMLDivElement) => {
        item.style.display = 'block';
      });
    }
  }, [isMobile, isHorizontalScreen, screenState]);
  const swiperParentWidth = useMemo(() => {
    let width = '';
    if (isMobile) {
      if (isHorizontalScreen) {
        width = parentWidth;
      } else {
        width = `${window.screen.availWidth}px`;
      }
    } else {
      if (!screenState) {
        width = parentWidth;
      } else {
        width = '100vw';
      }
    }
    if (width === '0px') {
      width = 'auto';
    }
    return width;
  }, [isHorizontalScreen, parentWidth, screenState]);
  const imgWidth = useMemo(() => {
    let width = '';
    if (screenState && !isHorizontalScreen) {
      width = '100vw';
    } else {
      width = 'auto';
    }
    if (!isMobile && screenState) {
      width = '100vw';
    }
    return width;
  }, [screenState, isHorizontalScreen]);
  const mouseEnterHandle = useCallback(() => {
    if (swiperParentWidth === 'auto') {
      const imgDom = document.getElementById('slide-0');
      if (!imgDom) return;
      const width = imgDom.offsetWidth;
      setParentWidth(`${width}px`);
    }
  }, [swiperParentWidth]);
  return (
    <>
      <FullScreen handle={screen}>
        <div style={{ width: swiperParentWidth }} className={'fullscreen-btn'} onMouseEnter={mouseEnterHandle}>
          <Swiper
            watchOverflow
            observer
            setWrapperSize
            // preloadImages={false}
            observeParents
            onObserverUpdate={(swiper) => {
              const imgDom = document.getElementById('slide-0');
              if (!imgDom) return;
              const width = imgDom.offsetWidth;
              if (swiper.width > width) {
                setParentWidth(`${width}px`);
                if (isMobile) {
                  if (isHorizontalScreen) {
                    setParentWidth(`${width}px`);
                  } else {
                    setParentWidth('100vw');
                  }
                }
              }
            }}
            navigation={{
              nextEl: '.swiper-btn-next',
              prevEl: '.swiper-btn-prev',
            }}
            className={imgList.length > 1 ? 'myswiper' : ''}
            modules={[Navigation, EffectFade, Pagination]}
            slidesPerView="auto"
            effect="fade"
            spaceBetween={30}
            pagination={{
              el: '.swiper-pagination-btn',
              type: 'fraction',
              renderFraction: (currentClass) => {
                const domStr = `<span class=${currentClass}></span>/<span>${totalPages}</span>`;
                return domStr;
              },
            }}
            onSlideChange={(swiper) => {
              const { activeIndex } = swiper;
              if (isLeftRef.current < activeIndex) {
                const partList = imgListRef.current.splice(0, 3);
                if (!partList.length) return;
                // 向右翻页
                setImgList((list) => {
                  return [...list, ...partList];
                });
              }
              aegis.reportEvent({
                name: 'ppt翻页', // 必填
                ext1: `当前页码：${activeIndex}`,
              });
              isLeftRef.current = activeIndex;
            }}
            maxBackfaceHiddenSlides={3}
          >
            {imgList.map((ele, index) => (
              <SwiperSlide key={ele}>
                <img
                  ref={imgRef}
                  src={ele}
                  id={`slide-${index}`}
                  style={{
                    maxWidth: isMobile ? '100vw' : screenState ? '100%' : 'calc(100vw - 200px)',
                    maxHeight: imgMaxHeight,
                    width: imgWidth,
                  }}
                />
              </SwiperSlide>
            ))}
            <div className="swiper-footer">
              <div className="screen-icon-content">
                <FullScreenSvg
                  state={screenState}
                  handleClick={() => {
                    aegis.reportEvent({
                      name: 'ppt全屏', // 必填
                      ext1: `全屏${!screenState ? '开启' : '关闭'}`,
                    });
                    if (IS_IOS) {
                      setIosFullScreen((val) => !val);
                      if (screenState) {
                        setScreenState(false);
                      } else {
                        setScreenState(true);
                      }
                      return;
                    }
                    if (screen.active) {
                      setScreenState(false);
                      screen.exit();
                    } else {
                      setScreenState(true);
                      screen.enter();
                    }
                  }}
                ></FullScreenSvg>
              </div>
            </div>
            <div className="swiper-btn swiper-btn-prev swiper-button-disabled">
              <div className="swiper-button-prev-btn"></div>
            </div>
            <div className="swiper-btn swiper-btn-next swiper-button-disabled">
              <div className="swiper-button-next-btn"></div>
            </div>
          </Swiper>
        </div>
      </FullScreen>
    </>
  );
};
