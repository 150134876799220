/*
 * @Author: princemwang
 * @Date: 2022-06-27 15:54:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-05-16 15:59:00
 */
/* eslint-disable */
import Cookie from 'js-cookie';
import config from '@/config';
import moment from 'moment';

export const getSessionId = (): string | undefined => {
  return Cookie.get(config.webcastId);
};
export const isWeiXin = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  const reg = /micromessenger/gi;
  if (reg.test(ua)) {
    return true;
  }
  return false;
};
interface Argument {
  length: number;
}
export const throttle = (fn: () => void, dely: number) => {
  let time = null;
  return () => {
    if (time) {
      return;
    }
    time = setTimeout(() => {
      fn?.();
      time = null;
    }, dely);
  };
};
/**
 * @description 路由匹配规则
 * @param webcastId
 * @returns
 */
export const regPath = (webcastId: string) => {
  const reg1 = new RegExp(`^/${webcastId}$`);
  const reg2 = new RegExp(`^/live/${webcastId}$`);
  const reg3 = new RegExp(`^/preview/${webcastId}$`);
  const reg4 = new RegExp(`^/virtual/${webcastId}$`);
  return (
    reg1.test(window.location.pathname) ||
    reg2.test(window.location.pathname) ||
    reg3.test(window.location.pathname) ||
    reg4.test(window.location.pathname)
  );
};
export const timeIsAmOrPm = (time: number): string => {
  let text = '';
  const hours = new Date(time).getHours();
  if (hours >= 0 && hours < 12) {
    text = 'am';
  } else {
    text = 'pm';
  }
  return text;
};
export const isSameDay = (date1: Date, date2: Date): boolean => {
  const str1 = String(date1).split(' ').slice(0, 3).join('');
  const str2 = String(date2).split(' ').slice(0, 3).join('');
  return str1 === str2;
};
const getEngMonth = (num: number) => {
  const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  };
  return months[num];
};
const getEngWeek = (num: number) => {
  const weeks = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };
  return weeks[num];
};
/**
 * @description 判断是否是美国夏令时间
 * @param time
 */
const isSummer = (time) => {
  const year = moment().year();
  const summerStartTime = moment(`${year}-03-01`).weekday(14).add(2, 'hours').valueOf();
  const summerEndTime = moment(`${year}-11-01`).weekday(7).add(2, 'hours').valueOf();
  const targetTime = moment(time).valueOf();
  return targetTime >= summerStartTime && targetTime <= summerEndTime;
};
const hktNum = 8 * 60 * 60 * 1000;
const edtNum = 4 * 60 * 60 * 1000;
const estNum = 5 * 60 * 60 * 1000;

export const utcToHktAndEdt = (utcTime: string, type: 'HKT' | 'EDT' | 'EST') => {
  let time = +new Date(utcTime);
  if (type === 'HKT') {
    time += hktNum;
  } else if (type === 'EDT') {
    time -= edtNum;
  } else {
    time -= estNum;
  }
  const date = new Date(time);
  const times = String(date).split(' ');
  const week = getEngWeek(date.getDay());
  const month = getEngMonth(date.getMonth());
  const days = timeIsAmOrPm(time);
  const hour = times[4].split(':');
  hour.pop();
  const hours = hour.join(':');
  const [, minutes] = hours.split(':');
  const hourFirst = date.getHours();
  const formatHour = Number(hourFirst) > 12 ? Number(hourFirst) - 12 : hourFirst;
  const endHours = `${formatHour}:${minutes}`;
  const text = `${week}, ${month} ${times[2]}, ${times[3]}/${endHours} ${days} ${type}`;
  return text;
};
const checkSameDay = (utcTime) => {
  const date1 = new Date(+new Date(utcTime) + hktNum);
  const date2 = new Date(+new Date(utcTime) - edtNum);
  const sameDay = isSameDay(date1, date2);
  return sameDay;
};
export const formatDateText = (utcTimes: string) => {
  const utcTime = utcTimes.replace(/-/g, '/');
  const sameDay = checkSameDay(utcTime);
  const hktText = utcToHktAndEdt(utcTime, 'HKT');
  const edtText = utcToHktAndEdt(utcTime, isSummer(utcTimes) ? 'EDT' : 'EST');
  let text = '';
  let formatHktText = '';
  let formatEdtText = '';
  if (sameDay) {
    formatEdtText = hktText.split('/').join(' ');
    [, formatHktText] = edtText.split('/');
  } else {
    formatHktText = edtText.split('/').join(' ');
    formatEdtText = hktText.split('/').join(' ');
  }
  text = `${formatEdtText} / ${formatHktText}`;
  return text;
};
export const formatHeadRightText = (utcTimes: string) => {
  const utcTime = utcTimes.replace(/-/g, '/');
  const date = new Date(+new Date(utcTime) + hktNum);
  const days = timeIsAmOrPm(+new Date(date));
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${month}/${day}/${year} ${hour}:${minute} ${days}`;
};
const u = navigator.userAgent;
export const IS_IOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

/**
 *
 * @param list array
 * @param part number
 */
export const sliceImgs = (part: number, time: number) => {
  const endFun = (lists: any[], cb) => {
    const startlist = lists.splice(0, part);
    cb(startlist);
    if (lists.length > 0) {
      setTimeout(() => {
        endFun(lists, cb);
      }, time);
    }
  };
  return endFun;
};
export const formatModayText = (utcTimes: string) => {
  let endStr = '';
  const utcTime = utcTimes.replace(/-/g, '/');
  const hktText = utcToHktAndEdt(utcTime, 'HKT');
  const edtText = utcToHktAndEdt(utcTime, isSummer(utcTimes) ? 'EDT' : 'EST');
  const [monthHkt, timeHkt] = hktText.split('/');
  const [monthEdt, timeEdt] = edtText.split('/');
  const monthHktArr = monthHkt.split(', ');
  const monthEdtArr = monthEdt.split(', ');
  const monthHktStr = `${monthHktArr[1]}, ${monthHktArr[2]}`;
  const monthEdtStr = `${monthEdtArr[1]}, ${monthEdtArr[2]}`;

  const sameDay = checkSameDay(utcTime);
  if (sameDay) {
    endStr = `${timeHkt} / ${timeEdt} on ${monthHktStr}.`;
  } else {
    endStr = `${timeHkt} on ${monthEdtArr[1]} / ${timeEdt} on ${monthHktArr[1]}, ${monthEdtArr[2]}.`;
  }
  return endStr;
};
export const isSafariBrowser = () => {
  return /Safari/.test(window.navigator.userAgent);
};
export const SPEED_OPTION = [
  { text: '1.5X', value: '1.5' },
  { text: '1.2X', value: '1.2' },
  { text: '1.0X', value: '1.0' },
  { text: '0.8X', value: '0.8' },
];
export const IS_GROUP =
  window.location.hostname === 'webcast.tencent.com' ||
  window.location.hostname === 'test2.rtc.qq.com' ||
  window.location.hostname === '127.0.0.1';

export const IS_HUYA = window.location.hostname === 'webcast.cdn.huya.com';
/**
 * @description 判断时间是否是下午
 * @param time
 * @returns
 */
export const isPm = (time) => {
  const hour = moment(time).hour();
  return hour >= 12;
};
export const utcToBeijin = (time) => {
  return moment.utc(time).add(8, 'hours').format('YYYY-MM-DD HH:mm:ss');
};
export const utcToEdt = (time) => {
  const unit = isSummer(time) ? 4 : 5;
  return moment.utc(time).subtract(unit, 'hours').format('YYYY-MM-DD HH:mm:ss');
};
export const tmeTimeString = (time) => {
  let str = '';
  let beiJinStr = '';
  let usaStr = '';
  const beiJinTime = utcToBeijin(time);
  const year = moment(beiJinTime).year();
  const beiJinMonth = moment(beiJinTime).month();
  const beiJinDate = moment(beiJinTime).date();
  const beiJinHour = moment(beiJinTime).hour();
  const beiJinPm = isPm(beiJinTime) ? 'P.M' : 'A.M';
  const beiJinMinute =
    moment(beiJinTime).minute() < 10 ? `0${moment(beiJinTime).minute()}` : moment(beiJinTime).minute();
  const beiHStr = beiJinHour > 12 ? beiJinHour - 12 : beiJinHour;
  const usaTime = utcToEdt(time);
  const usaMonth = moment(usaTime).month();
  const usaDate = moment(usaTime).date();
  const usaTimeIsPm = isPm(usaTime) ? 'P.M' : 'A.M';
  const usaHours = moment(usaTime).hour();
  const usaMinutes = moment(usaTime).minute() < 10 ? `0${moment(usaTime).minute()}` : moment(usaTime).minute();
  const hourStr = usaHours > 12 ? usaHours - 12 : usaHours;
  usaStr = `${getEngMonth(usaMonth)} ${usaDate}, ${year}, at ${hourStr}:${usaMinutes} ${usaTimeIsPm}. Eastern Time or `;
  beiJinStr = `${getEngMonth(
    beiJinMonth,
  )} ${beiJinDate}, ${year}, at ${beiHStr}:${beiJinMinute} ${beiJinPm}. Beijing/Hong Kong Time`;
  str = usaStr + beiJinStr;
  return str;
};
export const huyaTimeString = (time: string) => {
  let str = '';
  let beiJinStr = '';
  let usaStr = '';
  const beiJinTime = utcToBeijin(time);
  const year = moment(beiJinTime).year();
  const beiJinMonth = moment(beiJinTime).month();
  const beiJinDate = moment(beiJinTime).date();
  const beiJinHour = moment(beiJinTime).hour();
  const beiJinPm = isPm(beiJinTime) ? 'p.m.' : 'a.m.';
  const beiJinMinute =
    moment(beiJinTime).minute() < 10 ? `0${moment(beiJinTime).minute()}` : moment(beiJinTime).minute();
  const beiHStr = beiJinHour > 12 ? beiJinHour - 12 : beiJinHour;

  const usaTime = utcToEdt(time);
  const usaMonth = moment(usaTime).month();
  const usaDate = moment(usaTime).date();
  const usaTimeIsPm = isPm(usaTime) ? 'p.m.' : 'a.m.';
  const usaHours = moment(usaTime).hour();
  const usaMinutes = moment(usaTime).minute() < 10 ? `0${moment(usaTime).minute()}` : moment(usaTime).minute();
  const hourStr = usaHours > 12 ? usaHours - 12 : usaHours;
  usaStr = `${hourStr}:${usaMinutes} ${usaTimeIsPm} U.S. Eastern Time on ${getEngMonth(usaMonth)} ${usaDate}, ${year}`;
  beiJinStr = ` (${beiHStr}:${beiJinMinute} ${beiJinPm} Beijing/Hong Kong time on ${getEngMonth(
    beiJinMonth,
  )} ${beiJinDate}, ${year})`;
  str = usaStr + beiJinStr;
  return str;
};
export const FORM_OPTIONS = [
  { text: 'Sell-side Analyst', value: 'Sell-side Analyst' },
  { text: 'Buy-side Analyst', value: 'Buy-side Analyst' },
  { text: 'Institutional Investor', value: 'Institutional Investor' },
  { text: 'Individual Investor', value: 'Individual Investor' },
  { text: 'Journalist', value: 'Journalist' },
  { text: 'Employee', value: 'Employee' },
  { text: 'Other', value: 'Other' },
];
export const TITLE_OPTIONS = [
  {
    text: 'Mr',
    value: 'Mr',
  },
  {
    text: 'Mrs',
    value: 'Mrs',
  },
  {
    text: 'Miss',
    value: 'Miss',
  },
  {
    text: 'Ms',
    value: 'Ms',
  },
  {
    text: 'Dr',
    value: 'Dr',
  },
];
export const createUuid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};
export const isIphone15 = /iPhone; CPU iPhone OS 17_0 like Mac OS X/i.test(u);
