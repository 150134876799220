/*
 * @Author: princemwang
 * @Date: 2022-07-01 14:25:53
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-07-05 14:28:28
 */
import Aegis from '@tencent/aegis-web-sdk';
export type Taegis = {
  aegis: Aegis;
  init: (config?: object) => Aegis;
};
const { NODE_ENV } = process.env;
const aegisFunc: Taegis = {
  aegis: null,
  init: (config = {}) => {
    if (aegisFunc.aegis) return aegisFunc.aegis;
    return (aegisFunc.aegis = new Aegis({
      id: 'NIQXbNRjSvbwrqdPKX',
      reportApiSpeed: true,
      reportAssetSpeed: true,
      spa: true,
      beforeReport(log) {
        if (NODE_ENV === 'development') {
          return false;
        }
        return log;
      },
      ...config,
    }));
  },
};
const aegisUtils = () => {
  return aegisFunc;
};
export default aegisUtils;
