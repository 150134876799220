/*
 * @Author: princemwang
 * @Date: 2022-07-03 21:00:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-10 11:44:09
 */
import { StatusTip } from '@tencent/tea-component';

export const Loading = () => {
  return (
    <div className="loading-div">
      <StatusTip status="loading" loadingText="" />
    </div>
  );
};
