/*
 * @Author: princemwang
 * @Date: 2022-06-29 10:06:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-07-12 11:47:33
 */
import { createGlobalState } from '@/hooks';

export const createInitState = createGlobalState({
  status: 'loading',
});
export const createInitFormText = createGlobalState({
  title: 'Tencent Holdings Ltd 2022 First Quarter Results Announcement',
  startUtc: '2022/09/15 08:00:00',
});
export const createInitRefreshPpt = createGlobalState(false);
export const createIosFullScreen = createGlobalState(false);
